import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { EconomicProductSettings } from './types';

export const DefaultProductGroup: React.FC<EconomicProductSettings> = ({ economicUserDefaultData, productGroups, handleSelectChange }) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalProductGroup">
      <Form.Label column sm={6}>
        Specify default product group
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectDefaultProductGroup" label="Select product group">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.product_groups?.productGroupNumber} onChange={handleSelectChange}>
            <option data-key={'product_groups'}>Open this select menu</option>
            {productGroups &&
              productGroups.length !== 0 &&
              productGroups.map((productGroup) => (
                <option
                  key={`productGroup-${productGroup.productGroupNumber}`}
                  value={productGroup.productGroupNumber}
                  data-self={productGroup.self}
                  data-key={'product_groups'}
                  data-numberkey={'productGroupNumber'}
                  data-selfkey={'self'}
                >
                  {productGroup.name}
                </option>
              ))}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
