import { captureException, setExtra } from '@sentry/browser';
import axiosRetry from 'axios-retry';
import axios from 'axios';

import type { RawAxiosRequestHeaders, AxiosResponse, Method, AxiosError, AxiosRequestConfig } from 'axios';

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const axiosHeaderOptions = async (options?: RawAxiosRequestHeaders, withFiles?: boolean): Promise<RawAxiosRequestHeaders> => {
  let update: RawAxiosRequestHeaders = {
    ...options,
    Accept: 'application/json',
  };

  if (localStorage.jwt) {
    update = {
      ...update,
      Authorization: `Bearer ${localStorage.jwt}`,
    };
  }

  if (withFiles === false) {
    update = {
      ...update,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }

  return update;
};

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error.response && error.response.data) {
      setExtra('Response Error', error.response.data);
    }

    if (error.request && error.request.data) {
      setExtra('Request Error', error.request.data);
    }

    setExtra('Error Overview Data', error);

    if (error.config) {
      setExtra('Error Config Data', error.config);
    }

    captureException(error);

    return Promise.reject(error);
  },
);

export default async function fetcher(
  url: string,
  method: Method,
  withFiles = false,
  data?: unknown,
  options?: RawAxiosRequestHeaders,
  withCredentials?: boolean,
): Promise<AxiosResponse | null> {
  try {
    const config: AxiosRequestConfig = {};
    const getAxiosHeaderOptions = await axiosHeaderOptions(options, withFiles);

    config.headers = getAxiosHeaderOptions;
    config.method = method;
    config.validateStatus = (status: number) => {
      return status < 400;
    };

    if (data) {
      config.data = data;
    }

    config.withCredentials = withCredentials;

    const response = await axios(url, config);

    return response;
  } catch (error: unknown) {
    return (error as AxiosError).response as AxiosResponse;
  }
}
