import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { EconomicProductSettings } from './types';

export const LedgerCodes: React.FC<EconomicProductSettings> = ({ economicUserDefaultData, setEconomicUserDefaultData, ledgerCodes, productGroups }) => {
  const handleLedgerCodesSelectChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const dataset = event.target.dataset;
    const option = event.target.options[event.target.selectedIndex];
    const optionDataset = option.dataset;

    const id = dataset['id'];
    const self = optionDataset['self'];
    const value = option.value;

    if (!setEconomicUserDefaultData) {
      return;
    }

    if (id && self && value) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        ledger_codes: {
          ...economicUserDefaultData.ledger_codes,
          [id]: {
            productGroupNumber: parseInt(value),
            self: self,
          },
        },
      });
    } else if (id && economicUserDefaultData.ledger_codes) {
      delete economicUserDefaultData.ledger_codes[id];

      setEconomicUserDefaultData({
        ...economicUserDefaultData,
      });
    }
  };

  const setSelectValue = (key: string): number | string => {
    if (economicUserDefaultData.ledger_codes && economicUserDefaultData.ledger_codes[key]) {
      return economicUserDefaultData.ledger_codes[key].productGroupNumber;
    } else {
      return '';
    }
  };

  return (
    <>
      {ledgerCodes &&
        ledgerCodes.length !== 0 &&
        ledgerCodes.map((ledgerCode) => (
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalProductGroup" key={`${ledgerCode.id}-${ledgerCode.code}`}>
            <Form.Label column sm={6}>
              Specify product group for {ledgerCode.displayname}
            </Form.Label>
            <Col sm={6}>
              <FloatingLabel label="Select product group">
                <Form.Select
                  aria-label="Floating label select"
                  value={setSelectValue(`${ledgerCode.id}`)}
                  onChange={handleLedgerCodesSelectChange}
                  data-id={`${ledgerCode.id}`}
                >
                  <option data-key={'insurance'}>Open this select menu</option>
                  {productGroups &&
                    productGroups.length !== 0 &&
                    productGroups.map((productGroup) => (
                      <option
                        key={`${ledgerCode.code}ProductGroup-${productGroup.productGroupNumber}`}
                        value={productGroup.productGroupNumber}
                        data-self={productGroup.self}
                      >
                        {productGroup.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Form.Group>
        ))}
    </>
  );
};
