import React from 'react';
import { Row, Col, Form, Spinner, Button, Tabs, Tab } from 'react-bootstrap';
import { EconomicForm } from './economic';
import { RentmanForm } from './rentman';
import { MailgunForm } from './mailgun';
import { integrationByType, testConnection, updateUserIntegration } from '@src/Api';
import { CustomToast } from '@src/components/toast';

import rentmanEconomicLogo from '@public/img/rentman-economic-logo.png';

import type { EconomicUserSettings, RentmanUserSettings } from './types';
import { Container } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [economicUserDefaultData, setEmonomicUserDefaultData] = React.useState<EconomicUserSettings>({});
  const [rentmanUserDefaultData, setRentmanUserDefaultData] = React.useState<RentmanUserSettings>({});
  const [finalizeSyncStringError, setFinalizeSyncStringError] = React.useState<string | null>(null);
  const [invoiceTypeError, setInvoiceTypeError] = React.useState<string | null>(null);
  const [stockLocationSyncError, setStockLocationSyncError] = React.useState<string | null>(null);
  const [currentMonthPaymentTermsError, setCurrentMonthPaymentTermsError] = React.useState<string | null>(null);
  const [uploadPdfError, setUploadPdfError] = React.useState<string | null>(null);
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [errorType, setErrorType] = React.useState<string>('');
  const [activeKey, setActiveKey] = React.useState('rentman');

  React.useEffect(() => {
    const checkConnection = async () => {
      setLoading(true);
      const rentmanTest = await testConnection('rentman');
      const economicTest = await testConnection('economic');

      const storedTab = localStorage.getItem('settingsActiveTab');
      setActiveKey(storedTab || 'rentman');

      if (rentmanTest?.status !== 200) {
        navigate('/connection-config', { state: { error: 'One of your Rentman keys are no longer valid. Please enter a valid key.' } });
      } else if (economicTest?.status !== 200) {
        navigate('/connection-config', { state: { error: 'One of your E-conomic keys are no longer valid. Please enter a valid key.' } });
      } else {
        setLoading(false);
      }
    };

    const getDefaultData = async () => {
      const integrationData = await integrationByType('economic', 'data');
      if (integrationData) {
        setEmonomicUserDefaultData(integrationData.data.users_integrations_default_data);
      }

      const rentmanIntegrationData = await integrationByType('rentman', 'data');
      if (rentmanIntegrationData) {
        setRentmanUserDefaultData(rentmanIntegrationData.data.users_integrations_default_data);
      }
    };
    checkConnection();
    getDefaultData();
  }, []);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let hasErrors = false;

    setLoading(true);
    setErrorType('');

    if (
      (rentmanUserDefaultData.finalize_field === undefined || rentmanUserDefaultData.finalize_field.trim() === '') &&
      rentmanUserDefaultData.finalize_enabled === true
    ) {
      setFinalizeSyncStringError('*If you enable finalize sync, you have to enter a value.');
      hasErrors = true;
      setErrorType('rentman');
    }

    if (rentmanUserDefaultData.invoice_event_type === undefined || rentmanUserDefaultData.invoice_event_type.trim() === '') {
      setInvoiceTypeError('*Please select when to transfer invoices');
      hasErrors = true;
      setErrorType('rentman');
    }

    if (rentmanUserDefaultData.stock_location_active === true && rentmanUserDefaultData.stock_location === undefined) {
      setStockLocationSyncError('*Please select stock location');
      hasErrors = true;
      setErrorType('rentman');
    }

    if (rentmanUserDefaultData.upload_pdf === true && rentmanUserDefaultData.upload_pdf_type === undefined) {
      setUploadPdfError('*Please select pdf type');
      hasErrors = true;
      setErrorType('rentman');
    }

    if (
      economicUserDefaultData.autoPaymentTerms === true &&
      economicUserDefaultData.current_month_payment_terms === true &&
      economicUserDefaultData.current_month_payment_terms_custom_field_name === ''
    ) {
      setCurrentMonthPaymentTermsError('*Please specify a value for custom field name');
      hasErrors = true;
      setErrorType('economic');
    }

    if (hasErrors) {
      setLoading(false);
      return;
    }

    const economicResponse = await updateUserIntegration('economic', economicUserDefaultData);
    if (economicResponse) {
      setEmonomicUserDefaultData(economicResponse.data.users_integrations_default_data);
    }

    const rentmanResponse = await updateUserIntegration('rentman', rentmanUserDefaultData);
    if (rentmanResponse) {
      setRentmanUserDefaultData(rentmanResponse.data.users_integrations_default_data);
    }

    setShowToast(true);
    setLoading(false);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];
    const dataObject = option.dataset;

    const selectKey = dataObject['key'];
    const selectNumberKey = dataObject['numberkey'];
    const selectSelfKey = dataObject['selfkey'];

    if ((selectKey !== undefined && !selectNumberKey) || !selectSelfKey) {
      delete economicUserDefaultData[selectKey as string];

      setEmonomicUserDefaultData({
        ...economicUserDefaultData,
      });
    }

    if (!selectKey || !selectNumberKey || !selectSelfKey) return;

    setEmonomicUserDefaultData({
      ...economicUserDefaultData,
      [selectKey]: {
        [selectNumberKey]: parseInt(`${option.value}`),
        [selectSelfKey]: `${dataObject.self}`,
      },
    });
  };

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setActiveKey(key);
      localStorage.setItem('settingsActiveTab', key);
    }
  };

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          <CustomToast
            duration={3000}
            show={showToast}
            title="Success"
            message="Your settings were successfully changed"
            type="success"
            onClose={() => setShowToast(false)}
          />
        </AnimatePresence>
      </Container>
      <Row className="settings">
        <Col className="p-0">
          <div>
            <h1 className="settings-header">Settings</h1>
            <hr className="mt-2" />
          </div>
          <Form>
            <Col className="sticky-header" id="settingsHeader">
              <Row className="mt-4 mb-4">
                <Col md={10}>
                  <img src={rentmanEconomicLogo} alt="Rentman and E-conomic logo" width="300" loading="lazy" />
                </Col>
                <Col md={2} className="settings-save-btn">
                  <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" className="mr-2" /> ...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Here you can edit your settings for the Rentman - E-conomic integration.</p>
                </Col>
              </Row>
            </Col>
            {loading ? (
              <Container className="data-loading-spinner-container">
                <Spinner className="data-loading-spinner"></Spinner>
              </Container>
            ) : (
              <>
                <Tabs defaultActiveKey="rentman" className="settings-tab" activeKey={activeKey} onSelect={(key) => handleSelectTab(key)}>
                  <Tab
                    eventKey="rentman"
                    title={
                      errorType === 'rentman' ? (
                        <span>
                          Rentman{' '}
                          <span className="settings-tab-error">
                            <FontAwesomeIcon icon="circle-exclamation" />
                          </span>
                        </span>
                      ) : (
                        'Rentman'
                      )
                    }
                  >
                    <RentmanForm
                      setInvoiceTypeError={setInvoiceTypeError}
                      invoiceTypeError={invoiceTypeError}
                      rentmanUserDefaultData={rentmanUserDefaultData}
                      setRentmanUserDefaultData={setRentmanUserDefaultData}
                      finalizeSyncStringError={finalizeSyncStringError}
                      setFinalizeSyncStringError={setFinalizeSyncStringError}
                      stockLocationSyncError={stockLocationSyncError}
                      setStockLocationSyncError={setStockLocationSyncError}
                      uploadPdfError={uploadPdfError}
                      setUploadPdfError={setUploadPdfError}
                    />
                  </Tab>
                  <Tab
                    eventKey="economic"
                    title={
                      errorType === 'economic' ? (
                        <span>
                          E-conomic{' '}
                          <span className="settings-tab-error">
                            <FontAwesomeIcon icon="circle-exclamation" />
                          </span>
                        </span>
                      ) : (
                        'E-conomic'
                      )
                    }
                  >
                    <EconomicForm
                      economicUserDefaultData={economicUserDefaultData}
                      setEconomicUserDefaultData={setEmonomicUserDefaultData}
                      handleSelectChange={handleSelectChange}
                      currentMonthPaymentTermsError={currentMonthPaymentTermsError}
                      setCurrentMonthPaymentTermsError={setCurrentMonthPaymentTermsError}
                    />
                  </Tab>
                  <Tab eventKey="mailgun" title="Mailgun">
                    <MailgunForm rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} />
                  </Tab>
                </Tabs>
                <hr />
                <Row className="mt-4 mb-4">
                  <Col sm={10}></Col>
                  <Col sm={2} className="settings-save-btn">
                    <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                      {loading ? (
                        <>
                          <Spinner animation="border" size="sm" className="mr-2" /> ...
                        </>
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
