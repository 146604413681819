import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export const ScrollToTop: React.FC = () => {
  const [windowTop, setWindowTop] = useState<boolean>(true);

  const IsWindowTop = () => {
    setWindowTop(window.scrollY >= 0 && window.scrollY <= 300);
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', IsWindowTop);

    return () => {
      window.removeEventListener('scroll', IsWindowTop);
    };
  }, []);
  return (
    <>
      {!windowTop && (
        <Button className="scroll-to-top" onClick={handleScrollToTop}>
          <FontAwesomeIcon icon={'chevron-up'}></FontAwesomeIcon>
        </Button>
      )}
    </>
  );
};
