import React, { useEffect } from 'react';
import { PaymentData, PaymentTabProps } from './types';
import { resyncPayment } from '@src/Api';
import { Badge, Button, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PaymentTab: React.FC<PaymentTabProps> = ({ paymentData, loading, setToasts, onSortChange }) => {
  const [activePaymentIds, setActivePaymentIds] = React.useState<number[]>([]);
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = React.useState<keyof PaymentData | null>(null);

  const handlePaymentResyncClick = async (entryId: number): Promise<void> => {
    setActivePaymentIds((prevActivePaymentIds) => [...prevActivePaymentIds, entryId]);

    const response = await resyncPayment(entryId);

    if (response?.status === 200) {
      const message = response.data.message;
      setToasts((prevToasts) => [...prevToasts, { id: entryId, message }]);
    }

    setActivePaymentIds((prevActivePaymentIds) => prevActivePaymentIds.filter((id) => id !== entryId));
  };

  const handleSort = (field: keyof PaymentData) => {
    if (field === sortField) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
    onSortChange(sortField, sortOrder);
  };

  useEffect(() => {
    if (sortField && sortOrder) {
      onSortChange(sortField, sortOrder);
    }
  }, [sortField, sortOrder]);

  const getPaymentStatus = (status: number): string => {
    let statusText: string;

    if (status === 0) {
      statusText = 'Failed';
    } else if (status === 1 || status === 3) {
      statusText = 'Completed';
    } else {
      statusText = 'Processing';
    }

    return statusText;
  };

  const getStatusClasses = (status: number) => {
    let classes: string;

    if (status === 0) {
      classes = 'sync-overview-table-failed';
    } else if (status === 1 || status === 3) {
      classes = 'sync-overview-table-success';
    } else {
      classes = 'sync-overview-table-warning';
    }

    return classes;
  };

  return (
    <Table responsive className="sync-overview-table">
      <thead>
        <tr>
          <th onClick={() => handleSort('payment_created')}>
            <div className="sync-overview-table-header">
              Timestamp
              <div>
                {sortField === 'payment_created' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'payment_created' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('economic_entry_id')}>
            <div className="sync-overview-table-header">
              Entry ID
              <div>
                {sortField === 'economic_entry_id' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'economic_entry_id' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('economic_booked_invoice_id')}>
            <div className="sync-overview-table-header">
              Economic booked invoice ID
              <div>
                {sortField === 'economic_booked_invoice_id' &&
                  (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'economic_booked_invoice_id' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('rentman_invoice_id')}>
            <div className="sync-overview-table-header">
              Rentman Invoice ID
              <div>
                {sortField === 'rentman_invoice_id' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'rentman_invoice_id' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th>
            <div className="sync-overview-table-header">Payment status</div>
          </th>
          <th className="sync-overview-table-header-action">
            <div className="sync-overview-table-header">Action</div>
          </th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={5} className="table-loader">
              <Spinner className="data-loading-spinner"></Spinner>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {paymentData.length !== 0 &&
            paymentData.map((payment) => (
              <tr key={payment.economic_entry_id}>
                <td>{new Date(payment.payment_created).toLocaleString('da-DK')}</td>
                <td>{payment.economic_entry_id}</td>
                <td>{payment.economic_booked_invoice_id}</td>
                <td>{payment.rentman_invoice_id}</td>
                <td>
                  <Badge pill className={getStatusClasses(payment.payment_status)}>
                    {getPaymentStatus(payment.payment_status)}
                  </Badge>
                </td>
                <td>
                  {activePaymentIds.includes(payment.economic_entry_id) ? (
                    <Button className="sync-overview-table-btn">
                      <Spinner className="custom-spinner" animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  ) : (
                    <Button className="sync-overview-table-btn" onClick={() => handlePaymentResyncClick(payment.economic_entry_id)}>
                      <FontAwesomeIcon icon="arrows-rotate" />
                      <span>Re-sync data</span>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </Table>
  );
};
