import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicDeparmentSync: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleDepartmentSyncFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        department_sync_enabled: event.target.checked,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable deparment sync of invoice lines
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="department-sync-switch"
            label={economicUserDefaultData.department_sync_enabled ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.department_sync_enabled ? economicUserDefaultData.department_sync_enabled : false}
            onChange={handleDepartmentSyncFormCheckChange}
          />
        </Col>
      </Form.Group>
    </>
  );
};
