import { EconomicSettingModuleProps } from '@src/pages/settings/types';
import React from 'react';
import { Col, Form, FloatingLabel, Row } from 'react-bootstrap';

export const SelectedAttentionContact: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleSelectAttentionCustomerContact = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        selected_attention_customer_contact: event.target.value,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalSelectedAttentionCustomerContact">
        <Form.Label column sm={6}>
          Select which customer contact to use as attention customer contact.
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectAttentionCustomerContact" label="Select attention customer contact">
            <Form.Select
              value={economicUserDefaultData.selected_attention_customer_contact ? economicUserDefaultData.selected_attention_customer_contact : ''}
              onChange={handleSelectAttentionCustomerContact}
            >
              <option hidden value="" data-key={'selected_attention'}>
                Open this select menu
              </option>
              <option value="cust">Project customer contact person</option>
              <option value="admin">Admin contact person</option>
              <option value="default">Default contact person</option>
              <option value="first">First contact person</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
