import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicUploadEmail: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleEmailUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        upload_email: event.target.checked,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmailUpload">
      <Form.Label column sm={6}>
        Enable email for contact attention
      </Form.Label>
      <Col sm={6}>
        <Form.Check
          type="switch"
          id="email-upload-switch"
          label={economicUserDefaultData.upload_email ? 'Enabled' : 'Disabled'}
          checked={economicUserDefaultData.upload_email ? economicUserDefaultData.upload_email : false}
          onChange={handleEmailUploadChange}
        />
      </Col>
    </Form.Group>
  );
};
