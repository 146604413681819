import React, { useEffect, useState } from 'react';
import { UserProps } from '../dashboard/types';
import { getUsers, loginAsUser } from '@src/Api';
import { Table, Container, Spinner, Modal, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { deleteAccount } from '@src/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomToast } from '@src/components/toast';
import EditUserModal from './userModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<UserProps[]>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserProps | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);

  useEffect(() => {
    isAdmin();
    setDataLoading(true);
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const users = await getUsers();
      if (users) {
        setUsers(users.data.result);
      }
    } catch (error) {
      console.error('Failed to get users.');
    } finally {
      setDataLoading(false);
    }
  };

  const handleAddCookie = async (user: UserProps | undefined) => {
    if (user === undefined) {
      setToastError('User is undefined');
      setShowToast(true);
      return;
    }

    const token = localStorage.getItem('jwt') || '';
    localStorage.setItem('adminJwt', token);

    const response = await loginAsUser(user, token);

    if (response && response.data) {
      const newToken = response.data.newToken;

      if (newToken) {
        try {
          localStorage.setItem('jwt', newToken);
          location.reload();
        } catch (error) {
          console.log('Failed to set token in localStorage:', error);
        }
      }
    }
  };

  const isAdmin = async () => {
    const jwt = localStorage.getItem('jwt');
    const adminJwt = localStorage.getItem('adminJwt');

    if (jwt && adminJwt) {
      if (jwt !== adminJwt) {
        setIsAdminUser(true);
      } else {
        setIsAdminUser(false);
      }
    } else {
      setIsAdminUser(false);
    }
  };

  const handleDeleteUser = (userId: number) => {
    setUserToDelete(userId);
    setShowModal(true);
  };

  const handleOpenModal = (user: UserProps) => {
    setSelectedUser(user);
    setShowUserModal(true);
  };

  const confirmDeleteUser = async () => {
    if (userToDelete === null) return;

    setLoading(true);
    try {
      const response = await deleteAccount(userToDelete);
      if (response?.status === 200) {
        setShowToast(true);
        setUsers(users.filter((user) => user.users_id !== userToDelete));
        setUserToDelete(null);
      } else if (response?.data.error) {
        setToastError(response.data.error);
      }
    } catch (error) {
      console.error('Failed to delete user:', error);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <Row className="admin">
      <Col className="p-0">
        <div>
          <h1 className="admin-header">All users</h1>
          <hr className="mt-2" />
        </div>
        <Modal className="modal-placement" show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            {loading ? <Spinner className="custom-spinner-small-modal"></Spinner> : <Button onClick={confirmDeleteUser}>Yes, Delete</Button>}
          </Modal.Footer>
        </Modal>
        <EditUserModal showModal={showUserModal} handleModal={() => setShowUserModal(false)} selectedUser={selectedUser} refreshUsers={getAllUsers} />
        <Table className="admin-users-table" responsive>
          <thead>
            <tr>
              <th>User Id</th>
              <th>Email</th>
              <th>Company</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          {dataLoading ? (
            <tbody>
              <tr>
                <td colSpan={5} className="table-loader">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {users.map((user) => (
                <tr key={user.users_id}>
                  <td>{user.users_id}</td>
                  <td>{user.users_email}</td>
                  <td>{user.users_company_name}</td>
                  <td>{user.users_role}</td>
                  <td>
                    <button className="admin-users-table-btn admin-users-table-btn-delete" onClick={() => handleDeleteUser(user.users_id)}>
                      <span>Delete</span>
                      <FontAwesomeIcon icon="trash-can" />
                    </button>
                    <button className="admin-users-table-btn" onClick={() => handleOpenModal(user)}>
                      <span>Edit</span>
                      <FontAwesomeIcon icon="pen-to-square" />
                    </button>
                    <button disabled={isAdminUser} className="admin-users-table-btn" onClick={() => handleAddCookie(user)}>
                      <span>Login</span>
                      <FontAwesomeIcon icon="right-to-bracket" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        <Container className="toast-b-right">
          <CustomToast
            show={showToast}
            title={toastError ? 'Error' : 'User deleted'}
            message={toastError ? toastError : 'User successfully deleted from the database.'}
            onClose={() => setShowToast(false)}
            duration={5000}
            type={toastError ? 'error' : 'success'}
          />
        </Container>
      </Col>
    </Row>
  );
};
