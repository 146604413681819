import React from 'react';
// Styles
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import SyntaxHighlighter from 'react-syntax-highlighter';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
// Types
import { UsersIntergrationFormProps } from './types';
// Components
import CopyToClipboardButton from '@src/components/copy-clipboard';
// Images
import rentmanLogo from '@public/img/rentman-logo.jpg';

const RentmanForm: React.FC<UsersIntergrationFormProps> = ({
  config,
  loading,
  statusText,
  errorObject,
  formSubmitted,
  validationError,
  handleValidationError,
  handleRentmanValueChanged,
  handleFormSubmit,
  submissionSuccess,
  setSubmissionSuccess,
  submissionStatus,
}) => {
  return (
    <>
      <Card className="connection-card">
        <Card.Title className="connection-card-header">
          <img src={rentmanLogo} alt="Rentman logo" height="70" loading="lazy" />
        </Card.Title>
        <Card.Body className="p-0">
          <Form className="connection-card-form">
            <Form.Group className="connection-card-form-input" controlId="users_integrations_url">
              <Form.Label>URL</Form.Label>
              <Form.Control type="url" value="https://api.rentman.net" disabled />
            </Form.Group>
            <Form.Group className="connection-card-form-input" controlId="apiKey">
              <Form.Label>API Token</Form.Label>
              <Form.Control
                name="apikey"
                type="text"
                placeholder="Enter Rentman API token"
                value={submissionSuccess.rentman ? '****************' : config.rentman.users_integrations_api_data.apiKey}
                onChange={handleRentmanValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, rentman: false }))}
                className={!validationError['apikey'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['apikey'] ? '' : '* You need to enter your API token'}</p>
            </Form.Group>
            <Form.Group className="connection-card-form-input" controlId="apiHookKey">
              <Form.Label>Webhook Token</Form.Label>
              <Form.Control
                name="hookKey"
                type="text"
                placeholder="Enter Rentman Webhook Token"
                value={submissionSuccess.rentman ? '****************' : config.rentman.users_integrations_api_data.apiHookKey}
                onChange={handleRentmanValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, rentman: false }))}
                className={!validationError['hookKey'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['hookKey'] ? '' : '* You need to enter your Webhook Token'}</p>
            </Form.Group>
            <Button
              className="connection-card-form-btn"
              variant="primary"
              type="submit"
              disabled={loading['rentman'] === true ? true : false}
              onClick={(e) => handleFormSubmit(e, 'rentman')}
            >
              Save
            </Button>
          </Form>
          {loading['rentman'] && (
            <>
              <div className="p-4">
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </>
          )}
          {formSubmitted && statusText['rentman'] && (
            <>
              <div className="connection-card-form">
                <pre
                  className={submissionStatus.rentman ? 'success' : 'failed'}
                  dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(statusText['rentman'], undefined).replace(/"\s*([^"]+)"/g, '$1')) }}
                />
                {errorObject.rentman && (
                  <>
                    <div className="connection-card-form-error">
                      <SyntaxHighlighter language="json" style={atomOneLight}>
                        {JSON.stringify(errorObject.rentman, null, 2)}
                      </SyntaxHighlighter>
                      <CopyToClipboardButton textToCopy={JSON.stringify(errorObject.rentman, null)} />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RentmanForm;
