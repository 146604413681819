import React from 'react';

import { RentmanSyncState } from './syncState';
import { RentmanFinalizeSync } from './finalizeSync';
import { RentmanStockLocation } from './stockLocation';
import { RentmanUploadInvoicePdf } from './uploadInvoicePdf';

import rentmanLogo from '@public/img/rentman-logo.jpg';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';
import { ChooseRentmanStockLocation } from './chooseStockLocation';

export const RentmanForm: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  invoiceTypeError,
  setInvoiceTypeError,
  finalizeSyncStringError,
  setFinalizeSyncStringError,
  stockLocationSyncError,
  setStockLocationSyncError,
  uploadPdfError,
  setUploadPdfError,
}) => {
  return (
    <>
      <div className="settings-heading">
        <img src={rentmanLogo} alt="Rentman logo" height="75" loading="lazy" />
      </div>
      <h4 className="mb-4">Rentman</h4>
      <RentmanSyncState
        rentmanUserDefaultData={rentmanUserDefaultData}
        setRentmanUserDefaultData={setRentmanUserDefaultData}
        invoiceTypeError={invoiceTypeError}
        setInvoiceTypeError={setInvoiceTypeError}
      />

      <RentmanFinalizeSync
        rentmanUserDefaultData={rentmanUserDefaultData}
        setRentmanUserDefaultData={setRentmanUserDefaultData}
        finalizeSyncStringError={finalizeSyncStringError}
        setFinalizeSyncStringError={setFinalizeSyncStringError}
      />

      <RentmanStockLocation
        rentmanUserDefaultData={rentmanUserDefaultData}
        setRentmanUserDefaultData={setRentmanUserDefaultData}
        stockLocationSyncError={stockLocationSyncError}
        setStockLocationSyncError={setStockLocationSyncError}
      />

      <RentmanUploadInvoicePdf
        rentmanUserDefaultData={rentmanUserDefaultData}
        setRentmanUserDefaultData={setRentmanUserDefaultData}
        uploadPdfError={uploadPdfError}
        setUploadPdfError={setUploadPdfError}
      />

      <ChooseRentmanStockLocation rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} />
    </>
  );
};
