import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const EmailRecipient: React.FC<RentmanSettingModuleProps> = ({ rentmanUserDefaultData, setRentmanUserDefaultData }) => {
  const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData((prevState) => ({
      ...prevState,
      email_to: event.target.value,
    }));
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalRecipientsEmail">
      <Form.Label column sm={6}>
        Specify recipients email
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingInputRecipientsEmail" label="Specify which email to send to">
          <Form.Control type="text" value={rentmanUserDefaultData.email_to} onChange={handleEmailChanged} />
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
