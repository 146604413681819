import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { EconomicProductSettings } from './types';

export const InvoiceLinesPriorities: React.FC<EconomicProductSettings> = ({ economicUserDefaultData, setEconomicUserDefaultData, ledgerCodes }) => {
  const [priorities, setPriorities] = useState<number[]>([]);

  useEffect(() => {
    if (economicUserDefaultData.prioritized_invoice_lines) {
      setPriorities(economicUserDefaultData.prioritized_invoice_lines);
    } else {
      setPriorities(new Array(4).fill(''));
    }
  }, [economicUserDefaultData]);

  const handleEnablePrioritiesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        prioritize_invoice_lines_enabled: event.target.checked,
      });
    }
  };

  const handlePriorityChange = (index: number, selectedId: number) => {
    const newPriorities = [...priorities];
    newPriorities[index] = selectedId;
    setPriorities(newPriorities);

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        prioritized_invoice_lines: newPriorities,
      });
    }
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h4 className="mb-4">Product Lines</h4>
          <p>Set how you want to prioritize product lines on invoice</p>
        </Col>
      </Row>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalAdvancedInvoiceLines">
        <Form.Label column sm={6}>
          Enable prioritizing the final 4 invoice lines
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="prioritize-invoice-switch"
            label={economicUserDefaultData.prioritize_invoice_lines_enabled ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.prioritize_invoice_lines_enabled}
            onChange={handleEnablePrioritiesChanged}
            data-key="prioritize_invoice_lines_enabled"
          />
        </Col>
      </Form.Group>
      {economicUserDefaultData.prioritize_invoice_lines_enabled &&
        economicUserDefaultData.prioritize_invoice_lines_enabled === true &&
        ledgerCodes &&
        ledgerCodes.length !== 0 &&
        [0, 1, 2, 3].map((index) => (
          <Form.Group key={index} as={Row} className="mb-3">
            <Form.Label column sm={6}>
              Specify ledger code to use for line {index + 1}
            </Form.Label>
            <Col sm={6}>
              <FloatingLabel controlId={`floatingSelect-${index}`} label="Select ledger code">
                <Form.Select aria-label="Floating label select" value={priorities[index]} onChange={(e) => handlePriorityChange(index, Number(e.target.value))}>
                  <option value="">Open this select menu</option>
                  {ledgerCodes.map((ledgerCode) => (
                    <option key={ledgerCode.id} value={ledgerCode.id}>
                      {ledgerCode.displayname}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Form.Group>
        ))}
    </>
  );
};
