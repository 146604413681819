import React, { useState } from 'react';
import { Button, Row, Col, Form, Container, Spinner } from 'react-bootstrap';
import { forgotPass } from '@src/Api';
import { CustomToast } from '@src/components/toast';
import { validateEmail } from '@src/utils/emailUtils';
import { motion } from 'framer-motion';

export const ForgotPassword: React.FC = () => {
  const [resetEmail, setResetEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState<string | null>(null);

  //When form is submitted
  const handleForgotPass = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    // Check if email is not valid or not entered and stop the loading spinner
    if (!resetEmail || !isValidEmail) {
      setLoading(false);
      return;
    }

    try {
      const resetAction = await forgotPass(resetEmail);

      if (resetAction?.status === 200) {
        setToastError(null);
        setLoading(false);
        setShowToast(true);
      } else if (resetAction?.status === 404) {
        setLoading(false);
        setToastError('There is no user registered with this e-mail.');
        setShowToast(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <motion.div initial={{ opacity: 0, x: 200 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
        <Container>
          <Row>
            <Col>
              <h5 className="mb-2">Enter your email below</h5>
            </Col>
          </Row>
          <Form onSubmit={handleForgotPass}>
            <Row>
              <Col>
                <Form.Control
                  placeholder="Enter Email"
                  onChange={(e) => setResetEmail(e.target.value)}
                  type="email"
                  className={`mt-3 ${!isValidEmail ? 'input-error' : ''}`}
                  onBlur={() => {
                    const isValid = validateEmail(resetEmail);
                    setIsValidEmail(isValid);
                  }}
                  onFocus={() => setIsValidEmail(true)}
                />
              </Col>
            </Row>
            <Row className="m-2">
              <Col className="center-content-row ">
                {!showToast ? (
                  <p className="error-message error-size">{!isValidEmail ? 'Please enter a valid Email' : ''}</p>
                ) : (
                  <div className="toast-b-right">
                    <CustomToast
                      show={showToast}
                      title={toastError ? 'Error' : 'Email sent'}
                      message={toastError ? 'There is no user registered with this email.' : `Email successfully sent to ${resetEmail}`}
                      onClose={() => setShowToast(false)}
                      type={toastError ? 'error' : 'success'}
                      duration={5000}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {!loading ? (
                  <motion.div initial={{ x: 0, opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }}>
                    <Button type="submit">Send reset email</Button>
                  </motion.div>
                ) : (
                  <Spinner className="custom-spinner"></Spinner>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </motion.div>
    </>
  );
};
