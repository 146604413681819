import { useUsersContext } from '@src/contexts/users';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';

export const DataProcessingAgreementPage: React.FC = () => {
  const [userCreated, setUserCreated] = useState<Date | undefined>(undefined);
  const [dataProcessingAgreementDownloaded, setDataProcessingAgreementDownloaded] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const { usersState } = useUsersContext();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (buttonDisabled) {
      timer = setTimeout(() => {
        setButtonDisabled(false);
        setDataProcessingAgreementDownloaded(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [buttonDisabled]);

  useEffect(() => {
    setUserCreated(usersState.user.created_at);
  }, []);

  const handleDownloadClick = () => {
    setDataProcessingAgreementDownloaded(true);
    setButtonDisabled(true);

    setInterval(() => {
      setButtonDisabled(false);
      setDataProcessingAgreementDownloaded(false);
    }, 3000);
  };

  return (
    <>
      <Row className="account-content">
        <Col sm={12}>
          <p>{userCreated ? `You accepted the agreement on: ${format(new Date(userCreated), 'dd-MM-yyyy')}` : 'Date of agreement acceptance not available.'}</p>
          <a
            href={`${process.env.REACT_APP_API_URL}/download-data-processing-agreement`}
            download="DataProcessingAgreement.pdf"
            onClick={handleDownloadClick}
            className={`btn btn-primary ${buttonDisabled ? 'disabled' : ''}`}
          >
            {dataProcessingAgreementDownloaded ? 'Download completed' : 'Download databehandleraftale pdf'}
          </a>
        </Col>
      </Row>
    </>
  );
};
