import React from 'react';
import { SearchProps } from './types';
import { Button, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Search: React.FC<SearchProps> = ({ searchQuery, handleSearch, handleInputChange, handleClearSearch, placeholder }) => {
  return (
    <>
      <Col className="p-0">
        <div className="d-flex search">
          <div className="search-icon">
            <FontAwesomeIcon icon="magnifying-glass" />
          </div>
          <InputGroup>
            <input
              type="search"
              placeholder={placeholder}
              id="form1"
              className="form-control search-input"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(searchQuery);
                }
              }}
            />
            <Button className="search-cancel" variant="link" onClick={() => handleClearSearch()}>
              <FontAwesomeIcon icon="xmark" />
            </Button>
          </InputGroup>
        </div>
      </Col>
    </>
  );
};
