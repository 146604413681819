import React from 'react';
import { CustomButtonProps } from './types';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const CustomButton: React.FC<CustomButtonProps> = ({ title, description, image, href }) => {
  return (
    <Col className="custom-button">
      <Link to={`${href}`} className="custom-button-anchor">
        <p className="custom-button-header">{title}</p>
        <img className="custom-button-image" src={`${image}`} alt="" />
        <p className="custom-button-description">{description}</p>
      </Link>
    </Col>
  );
};
