import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Spinner, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Types
import { LogsData } from './types';
// Components
import ModalComponent from './modal';
import { PaginationComponent } from '../../components/pagination';
// API
import { allLogs, searchedLogs } from '@src/Api';
import { Search } from '@src/components/search';
import { ScrollToTop } from '@src/components/scroll-to-top';

export const LogPage: React.FC = () => {
  const [logsData, setLogsData] = useState<LogsData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(25);
  const [resultsPerPage, setResultsPerPage] = useState<number>(25);
  const [selectedLog, setSelectedLog] = useState<LogsData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = React.useState<keyof LogsData | null>(null);

  const handleShowData = (log: LogsData) => {
    if (selectedLog.includes(log)) {
      setSelectedLog(selectedLog.filter((l) => l !== log));
    } else {
      setSelectedLog([...selectedLog, log]);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleClearSearch = () => {
    setClearSearch(true);
  };

  const handleSort = (field: keyof LogsData) => {
    if (field === sortField) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
    setCurrentPage(0);
  };

  useEffect(() => {
    const fetchLogs = async (): Promise<void> => {
      setLoading(true);
      if (clearSearch) {
        setSearchQuery('');
        setSelectedOption('');
        setClearSearch(false);
      }

      const sortFieldString = sortField ? sortField.toString() : 'logs_id';

      let response;
      if (searchQuery || selectedOption) {
        response = await searchedLogs(resultsPerPage, currentPage, searchQuery, selectedOption, sortOrder, sortFieldString);
      } else {
        response = await allLogs(resultsPerPage, currentPage, sortOrder, sortFieldString);
      }

      if (response) {
        setLogsData(response.data.results);
        setTotalResults(response.data.total);
      }
      setLoading(false);
    };

    fetchLogs();
  }, [sortField, sortOrder, currentPage, searchQuery, resultsPerPage, selectedOption, clearSearch]);

  const getStatusClasses = (status: string) => {
    let classes: string;

    if (status === 'Failed') {
      classes = 'log-table-failed';
    } else if (status === 'Completed') {
      classes = 'log-table-success';
    } else {
      classes = 'log-table-warning';
    }

    return classes;
  };

  const handleDropdownChange = (value: number) => {
    setResultsPerPage(value);
  };

  return (
    <>
      <Row className="log">
        <Col className="p-0">
          <div>
            <h1 className="log-header">Logs</h1>
            <hr className="mt-2" />
          </div>

          <Row className="log-search">
            <Col sm="auto">
              <select className="form-select log-search-input" aria-label="Default select" value={selectedOption} onChange={handleSelectChange}>
                <option value="">All</option>
                <option value="invoice">Invoice</option>
                <option value="credit note">Credit note</option>
                <option value="Webhook Request">Webhook</option>
              </select>
            </Col>
            <Search
              searchQuery={searchQuery}
              handleSearch={handleSearch}
              handleInputChange={handleInputChange}
              handleClearSearch={handleClearSearch}
              placeholder="Search logs"
            ></Search>
          </Row>
          <div className="mt-4">
            <Table className="log-table" responsive>
              <thead>
                <tr>
                  <th onClick={() => handleSort('logs_created')}>
                    <div className="log-table-header">
                      Timestamp
                      <div>
                        {sortField === 'logs_created' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                        {sortField !== 'logs_created' && <FontAwesomeIcon icon="sort" />}
                      </div>
                    </div>
                  </th>
                  <th onClick={() => handleSort('logs_id')}>
                    <div className="log-table-header">
                      Log ID
                      <div>
                        {sortField === 'logs_id' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                        {sortField !== 'logs_id' && <FontAwesomeIcon icon="sort" />}
                      </div>
                    </div>
                  </th>
                  <th onClick={() => handleSort('logs_invoice_id')}>
                    <div className="log-table-header">
                      Invoice ID
                      <div>
                        {sortField === 'logs_invoice_id' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                        {sortField !== 'logs_invoice_id' && <FontAwesomeIcon icon="sort" />}
                      </div>
                    </div>
                  </th>
                  <th onClick={() => handleSort('logs_type')}>
                    <div className="log-table-header">
                      Type
                      <div>
                        {sortField === 'logs_type' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                        {sortField !== 'logs_type' && <FontAwesomeIcon icon="sort" />}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="log-table-header">Event</div>
                  </th>
                  <th onClick={() => handleSort('logs_status')}>
                    <div className="log-table-header">
                      Status
                      <div>
                        {sortField === 'logs_status' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                        {sortField !== 'logs_status' && <FontAwesomeIcon icon="sort" />}
                      </div>
                    </div>
                  </th>
                  <th className="log-table-header-action">
                    <div className="log-table-header">Action</div>
                  </th>
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={7} className="table-loader">
                      <Spinner className="data-loading-spinner"></Spinner>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {logsData.map((log, index) => (
                    <>
                      <tr key={index}>
                        <td>{new Date(log.logs_created).toLocaleString('da-DK')}</td>
                        <td>{log.logs_id}</td>
                        <td>{log.logs_invoice_id}</td>
                        <td>{log.logs_type}</td>
                        <td>{log.logs_message}</td>
                        <td>
                          <Badge pill className={getStatusClasses(log.logs_status)}>
                            {log.logs_status}
                          </Badge>
                        </td>
                        <td>
                          <Button className="log-table-btn" onClick={() => handleShowData(log)}>
                            <FontAwesomeIcon icon={selectedLog.includes(log) ? 'minus' : 'plus'} />
                            <span>Show data</span>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        {selectedLog.includes(log) && (
                          <td colSpan={7}>
                            <ModalComponent selectedLog={log} />
                          </td>
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              )}
            </Table>
          </div>

          <Row className="log-footer">
            <Col>{`Total logs: ${totalResults}`}</Col>
            <Col className="d-flex justify-content-center">
              <PaginationComponent
                itemsCount={totalResults}
                itemsPerPage={resultsPerPage}
                currentPage={currentPage + 1}
                setCurrentPage={(pageChangeTo) => {
                  setCurrentPage(pageChangeTo - 1);
                }}
              />
            </Col>
            <Col className="log-footer-dropdown">
              <select className="form-select dropdown" value={resultsPerPage} onChange={(e) => handleDropdownChange(parseInt(e.target.value))}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </Col>
          </Row>
        </Col>
      </Row>
      <ScrollToTop></ScrollToTop>
    </>
  );
};
