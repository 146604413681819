import React from 'react';
import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import { Row, Col, Form } from 'react-bootstrap';

import { EconomicInternationalVatZones } from './internationalVatZones';
import { EconomicInternationalFactors } from './internationalFactors';
import { EconomicInternationalCurrencies } from './internationalCurrencies';
import { EconomicInternationalLayouts } from './internationalLayouts';

export const InternationalForm: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData, handleSelectChange }) => {
  const handleInternationalInvoicesFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        international_invoices_enabled: event.target.checked,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable using international invoices
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="international-invoices-switch"
            label={economicUserDefaultData.international_invoices_enabled ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.international_invoices_enabled ? economicUserDefaultData.international_invoices_enabled : false}
            onChange={handleInternationalInvoicesFormCheckChange}
          />
        </Col>
      </Form.Group>
      {economicUserDefaultData.international_invoices_enabled && (
        <>
          <EconomicInternationalFactors economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
          <EconomicInternationalCurrencies economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
          <EconomicInternationalVatZones economicUserDefaultData={economicUserDefaultData} handleSelectChange={handleSelectChange} />
          <EconomicInternationalLayouts economicUserDefaultData={economicUserDefaultData} handleSelectChange={handleSelectChange} />
        </>
      )}
    </>
  );
};
