import { EconomicSettingModuleProps } from '@src/pages/settings/types';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { DraggableList } from './draggableList';

export const CustomerContactPriorities: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCustomerContactPriorities">
        <Form.Label column sm={6}>
          Drag items to prioritize which customer or company to use as customer contact, where the first item in the list has the highest priority
        </Form.Label>
        <Col sm={6}>
          <DraggableList economicUserDefaultData={economicUserDefaultData} setEconomicUserDefaultData={setEconomicUserDefaultData} />
        </Col>
      </Form.Group>
    </>
  );
};
