import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { stockLocationsByUser } from '@src/Api';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';
import type { StockLocation } from './types';

export const RentmanStockLocation: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  stockLocationSyncError,
  setStockLocationSyncError,
}) => {
  const [stockLocations, setStockLocations] = React.useState<StockLocation[]>([]);

  React.useEffect(() => {
    const getStockLocations = async () => {
      const stockLocationsResponse = await stockLocationsByUser();
      if (stockLocationsResponse) {
        setStockLocations(stockLocationsResponse.data);
      }
    };

    getStockLocations();
  }, []);

  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      stock_location_active: event.target.checked,
    });
  };

  const handleStockLocationChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    if (setStockLocationSyncError) {
      setStockLocationSyncError(null);
    }

    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      stock_location: [].slice.call(event.target.selectedOptions).map((item: HTMLOptionElement) => parseInt(`${item.value}`)),
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable sync based on stock location of the project
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="stock-location-switch"
            label={rentmanUserDefaultData.stock_location_active ? 'Enabled' : 'Disabled'}
            checked={rentmanUserDefaultData.stock_location_active ? rentmanUserDefaultData.stock_location_active : false}
            onChange={handleFormCheckChange}
          />
        </Col>
      </Form.Group>
      {rentmanUserDefaultData.stock_location_active && rentmanUserDefaultData.stock_location_active === true && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceProjectId">
          <Form.Label column sm={6}>
            Select stock location
          </Form.Label>
          <Col sm={6}>
            <Form.Select
              aria-label="Floating label select"
              multiple
              className={stockLocationSyncError && rentmanUserDefaultData.stock_location_active === true ? 'error-field' : ''}
              value={rentmanUserDefaultData.stock_location?.map((location) => `${location}`)}
              onChange={handleStockLocationChange}
            >
              <option hidden value="">
                Open this select menu
              </option>
              {stockLocations.length !== 0 &&
                rentmanUserDefaultData.stock_location_active === true &&
                stockLocations.map((stockLocation) => (
                  <option key={`stockLocation-${stockLocation.id}`} value={stockLocation.id}>
                    {stockLocation.displayname}
                  </option>
                ))}
            </Form.Select>
            <div className="error-container-settings">
              <p className={stockLocationSyncError ? 'error-text' : ''}>{stockLocationSyncError}</p>
            </div>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
