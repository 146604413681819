import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicQuotationContractEqual: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handlenContractEqualFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        contract_equal: event.target.checked,
      });
    }
  };

  const handleQuotationEqualFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        quotation_equal: event.target.checked,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable only transferring invoices when invoice total is equal to contract.
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="contract-equal-switch"
            label={economicUserDefaultData.contract_equal ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.contract_equal ? economicUserDefaultData.contract_equal : false}
            onChange={handlenContractEqualFormCheckChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable only transferring invoices when invoice total is equal to quotation.
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="quotation-equal-switch"
            label={economicUserDefaultData.quotation_equal ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.quotation_equal ? economicUserDefaultData.quotation_equal : false}
            onChange={handleQuotationEqualFormCheckChange}
          />
        </Col>
      </Form.Group>
    </>
  );
};
