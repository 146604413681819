import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FoldableFailureProps } from './types';

const FoldableFailure: React.FC<FoldableFailureProps> = ({ failure, syntaxHighlight }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const { message, ...details } = failure;

  const eventKey = message.replace(/\s+/g, '');

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header onClick={toggleOpen} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
        {failure.message}
      </Accordion.Header>
      <Accordion.Body>{isOpen && <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(details, null, 2)) }} />}</Accordion.Body>
    </Accordion.Item>
  );
};

export default FoldableFailure;
