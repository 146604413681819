import React from 'react';
// Styles
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import SyntaxHighlighter from 'react-syntax-highlighter';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
// Types
import { UsersIntergrationFormProps } from './types';
// Components
import CopyToClipboardButton from '@src/components/copy-clipboard';
// Images
import economicLogo from '@public/img/economic-logo.png';

const EconomicForm: React.FC<UsersIntergrationFormProps> = ({
  config,
  loading,
  statusText,
  errorObject,
  formSubmitted,
  validationError,
  handleValidationError,
  handleRentmanValueChanged,
  handleFormSubmit,
  submissionSuccess,
  setSubmissionSuccess,
  submissionStatus,
}) => {
  return (
    <>
      <Card className="connection-card">
        <Card.Title className="connection-card-header">
          <img src={economicLogo} alt="E-conomic logo" height="60" loading="lazy" />
        </Card.Title>
        <Card.Body className="p-0">
          <Form className="connection-card-form">
            <Form.Group className="connection-card-form-input" controlId="users_integrations_url">
              <Form.Label>URL</Form.Label>
              <Form.Control type="url" value="https://restapi.e-conomic.com" disabled />
            </Form.Group>
            <Form.Group className="connection-card-form-input" controlId="apiSecret">
              <Form.Label>App Secret Token</Form.Label>
              <Form.Control
                name="secretToken"
                type="text"
                placeholder="Enter Secret Token"
                value={submissionSuccess.economic ? '****************' : config.economic.users_integrations_api_data.apiSecret}
                onChange={handleRentmanValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, economic: false }))}
                className={!validationError['secretToken'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['secretToken'] ? '' : '* You need to enter your Secret Token'}</p>
            </Form.Group>
            <Form.Group className="connection-card-form-input" controlId="apiKey">
              <Form.Label>Grant Token</Form.Label>
              <Form.Control
                name="grantToken"
                type="text"
                placeholder="Enter Grant Token"
                value={submissionSuccess.economic ? '****************' : config.economic.users_integrations_api_data.apiKey}
                onChange={handleRentmanValueChanged}
                onBlur={handleValidationError}
                onFocus={() => setSubmissionSuccess((prevState) => ({ ...prevState, economic: false }))}
                className={!validationError['grantToken'] ? '' : 'error-field'}
              />
              <p className="error-text">{!validationError['grantToken'] ? '' : '* You need to enter your Secret Token'}</p>
            </Form.Group>

            <Button
              className="connection-card-form-btn"
              variant="primary"
              type="submit"
              disabled={loading['economic'] === true ? true : false}
              onClick={(e) => handleFormSubmit(e, 'economic')}
            >
              Save
            </Button>
          </Form>
          {loading['economic'] && (
            <>
              <div className="p-4">
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </>
          )}

          {formSubmitted && statusText['economic'] && (
            <>
              <div className="connection-card-form">
                <pre
                  className={submissionStatus.economic ? 'success' : 'failed'}
                  dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(statusText['economic'], undefined).replace(/"\s*([^"]+)"/g, '$1')) }}
                />
                {errorObject.economic && (
                  <>
                    <div className="connection-card-form-error">
                      <SyntaxHighlighter language="json" style={atomOneLight}>
                        {JSON.stringify(errorObject.economic, null, 2)}
                      </SyntaxHighlighter>
                      <CopyToClipboardButton textToCopy={JSON.stringify(errorObject.economic, null)} />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default EconomicForm;
