import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { vatZonesByUser } from '@src/Api';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import { VatZones } from '../types';

export const EconomicInternationalVatZones: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, handleSelectChange }) => {
  const [vatZones, setVatZones] = React.useState<VatZones[]>([]);

  React.useEffect(() => {
    const getVatZones = async () => {
      const vatZonesResponse = await vatZonesByUser();
      if (vatZonesResponse) {
        setVatZones(vatZonesResponse.data);
      }
    };
    getVatZones();
  }, []);

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalVatZone">
        <Form.Label column sm={6}>
          Specify VAT zone for EU customers
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectVatZone" label="Select vat zone">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.vat_zone_eu?.vatZoneNumber} onChange={handleSelectChange}>
              <option hidden value="" data-key={'vat_zone_eu'}>
                Open this select menu
              </option>
              {vatZones.length !== 0 &&
                vatZones.map((vatZone) => (
                  <option
                    key={`vatZone-${vatZone.vatZoneNumber}`}
                    value={vatZone.vatZoneNumber}
                    data-self={vatZone.self}
                    data-key={'vat_zone_eu'}
                    data-numberkey={'vatZoneNumber'}
                    data-selfkey={'self'}
                  >
                    {vatZone.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalVatZone">
        <Form.Label column sm={6}>
          Specify VAT zone for customers outside the EU
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectVatZone" label="Select vat zone">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.vat_zone_outside_eu?.vatZoneNumber} onChange={handleSelectChange}>
              <option hidden value="" data-key={'vat_zone_outside_eu'}>
                Open this select menu
              </option>
              {vatZones.length !== 0 &&
                vatZones.map((vatZone) => (
                  <option
                    key={`vatZone-${vatZone.vatZoneNumber}`}
                    value={vatZone.vatZoneNumber}
                    data-self={vatZone.self}
                    data-key={'vat_zone_outside_eu'}
                    data-numberkey={'vatZoneNumber'}
                    data-selfkey={'self'}
                  >
                    {vatZone.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
