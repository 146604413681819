import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import { allCurrencies } from '@src/Api';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import type { Currencies, SelectedCurrency } from './types';

export const EconomicDefaultCurrency: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const [currencies, setCurrencies] = React.useState<Currencies[]>([]);

  React.useEffect(() => {
    const getCurrencies = async () => {
      const currenciesResponse = await allCurrencies();
      if (currenciesResponse) {
        setCurrencies(currenciesResponse.data);
      }
    };

    getCurrencies();
  }, []);

  const handleCurrencyChange = (selectedOption: SelectedCurrency | null) => {
    if (selectedOption && setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        currencies: selectedOption.value,
      });
    }
  };

  const currencyOptions = currencies.map((currency) => ({
    value: currency.currencies_code,
    label: currency.currencies_name,
  }));

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalCurrency">
      <Form.Label column sm={6}>
        Specify default currency
      </Form.Label>
      <Col sm={6} className="custom-z-index">
        <Select
          classNamePrefix="react-select"
          aria-label="Floating label select"
          value={currencyOptions.find((option) => option.value === economicUserDefaultData.currencies)}
          onChange={handleCurrencyChange}
          options={currencyOptions}
        />
      </Col>
    </Form.Group>
  );
};
