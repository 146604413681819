import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import { economicUrl } from '@src/config/economic';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const CreditNoteForm: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleRedfundProductLineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        credit_note_line: event.target.value,
      });
    }
  };

  const handleRefundProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        credit_note_product: {
          productNumber: `${event.target.value}`,
          self: `${economicUrl}/products/${event.target.value}`,
        },
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRefundLine">
        <Form.Label column sm={6}>
          Specify line text for refund product
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRefundLine" label="Refund line">
            <Form.Control type="text" value={economicUserDefaultData.credit_note_line} onChange={handleRedfundProductLineChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRefund">
        <Form.Label column sm={6}>
          Specify refund product number
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRefund" label="Product number for refund">
            <Form.Control type="text" value={economicUserDefaultData.credit_note_product?.productNumber} onChange={handleRefundProductChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
