import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { EmailRecipient } from './emailRecipient';
import { ConnectionEmailEnabled } from './mailOnConnectionFailed';
import { InvoiceEmailEnabled } from './mailOnInvoiceFailed';

import mailgunLogo from '@public/img/mailgun-logo.svg';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const MailgunForm: React.FC<RentmanSettingModuleProps> = ({ rentmanUserDefaultData, setRentmanUserDefaultData }) => {
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <div>
            <div className="settings-heading">
              <img src={mailgunLogo} alt="Mailgun logo" height="45" loading="lazy" />
            </div>
            <h4 className="mb-4">Mailgun</h4>
            <p>Set when and where to recieve emails from Mailgun</p>

            <EmailRecipient rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} />

            <ConnectionEmailEnabled rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} />

            <InvoiceEmailEnabled rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} />
          </div>
        </Col>
      </Row>
    </>
  );
};
