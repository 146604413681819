import React from 'react';

import { UsersContextProvider } from '@src/contexts/users';
import { MainPage } from '@src/pages/main';

import type { Children } from '@src/types';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, far);

const AppContextProvider: React.FC<Children> = ({ children }) => {
  return <UsersContextProvider>{children}</UsersContextProvider>;
};

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <MainPage />
    </AppContextProvider>
  );
};

export default App;
