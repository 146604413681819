import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const RentmanFinalizeSync: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  finalizeSyncStringError,
  setFinalizeSyncStringError,
}) => {
  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      finalize_enabled: event.target.checked,
    });
  };

  const handleInvoiceFinalizeSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setFinalizeSyncStringError) {
      setFinalizeSyncStringError(null);
    }

    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      finalize_field: event.target.value,
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable finalize sync based on custom field of project
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="finalize-switch"
            label={rentmanUserDefaultData.finalize_enabled ? 'Enabled' : 'Disabled'}
            checked={rentmanUserDefaultData.finalize_enabled ? rentmanUserDefaultData.finalize_enabled : false}
            onChange={handleFormCheckChange}
          />
        </Col>
      </Form.Group>
      {rentmanUserDefaultData.finalize_enabled && rentmanUserDefaultData.finalize_enabled === true && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceProjectId">
          <Form.Label column sm={6}>
            Specify custom field for finalize sync
          </Form.Label>
          <Col sm={6}>
            <FloatingLabel controlId="floatingInputInvoiceProjectId" label="Custom field of project">
              <Form.Control
                className={finalizeSyncStringError && rentmanUserDefaultData.finalize_enabled === true ? 'error-field' : ''}
                type="text"
                value={rentmanUserDefaultData.finalize_field}
                onChange={handleInvoiceFinalizeSyncChange}
                onBlur={() => {
                  handleInvoiceFinalizeSyncChange;
                }}
              />
            </FloatingLabel>
            <div className="error-container-settings">
              <p className={finalizeSyncStringError ? 'error-text' : ''}>{finalizeSyncStringError}</p>
            </div>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
