import { ValidateFieldsArgs, ValidateFieldsResult } from './types';

export const validateFields = ({ newPassword, confirmNewPassword }: ValidateFieldsArgs): ValidateFieldsResult => {
  let passwordError = null;
  let confirmPasswordError = null;

  if (!newPassword.trim()) {
    passwordError = 'Password field is required.';
  }

  if (!confirmNewPassword.trim()) {
    confirmPasswordError = 'Confirm password field is required.';
  }

  let mismatchError = null;
  if (newPassword.trim() && confirmNewPassword.trim() && newPassword !== confirmNewPassword) {
    mismatchError = 'Password fields do not match.';
  }

  return {
    passwordError,
    confirmPasswordError,
    mismatchError,
    hasError: Boolean(passwordError || confirmPasswordError || mismatchError),
  };
};
