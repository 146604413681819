import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import { economicUrl } from '@src/config/economic';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicRoundingDifference: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleRoundingDifferenceLineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        difference_line: event.target.value,
      });
    }
  };

  const handleRoundingDifferenceProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        difference_product: {
          productNumber: `${event.target.value}`,
          self: `${economicUrl}/products/${event.target.value}`,
        },
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRoundingDifference">
        <Form.Label column sm={6}>
          Specify line text for rounding difference
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRoundingDifference" label="Rounding difference">
            <Form.Control type="text" value={economicUserDefaultData.difference_line} onChange={handleRoundingDifferenceLineChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRoundingDifferenceItemNumber">
        <Form.Label column sm={6}>
          Specify product number for rounding difference
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRoundingDifferenceItemNumber" label="Product number for rounding difference">
            <Form.Control type="text" value={economicUserDefaultData.difference_product?.productNumber} onChange={handleRoundingDifferenceProductChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
