import { JwtInterface } from './types';

export const getLocalJwtToken = (): string => {
  return localStorage.getItem('jwt') as string;
};

export const setLocalJwtToken = (token: string): void => {
  localStorage.setItem('jwt', token);
};

export const removeLocalJwtToken = (): void => {
  localStorage.removeItem('jwt');
  localStorage.removeItem('adminJwt');
};

export const decodeJwtToken = (token: string): JwtInterface => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(atob(base64)) as JwtInterface;
};

export const tokenExpired = (token: string): boolean => {
  const expireToken = getTokenExpiryTime(token);
  if (expireToken) {
    return expireToken < Date.now();
  } else {
    return true;
  }
};

export const getTokenExpiryTime = (token: string): number | null => {
  try {
    const payload: JwtInterface = decodeJwtToken(token);
    if (payload && payload.exp) {
      return payload.exp * 1000; // Convert to milliseconds
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
