import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { ReferenceOptions } from './types';

export const EconomicOtherReference: React.FC<ReferenceOptions> = ({ economicUserDefaultData, setEconomicUserDefaultData, referenceOptions }) => {
  const handleOtherReferenceChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        other_reference: option.value,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceOtherReference">
      <Form.Label column sm={6}>
        Select value for other reference
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectInvoiceOtherReference" label="Select invoice other reference">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.other_reference} onChange={handleOtherReferenceChange}>
            {referenceOptions('other-reference')}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
