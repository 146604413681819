import React from 'react';

import { unitsByUser, productGroupsByUser, ledgerCodesByUser } from '@src/Api';

import { AdvancedInvoiceLines } from './advanced_invoice_lines';

import { DefaultProductGroup } from './default_product_group';
import { DefaultProductUnit } from './default_product_unit';
import { CrewProductLedgerCode } from './crew_product_ledger_code';
import { CrewProductUnit } from './crew_product_unit';
import { LedgerCodes } from './ledger_codes';

import type { Units, ProductsGroups, LedgerCode } from './types';
import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import { InvoiceLinesPriorities } from './invoice_lines_priorities';

export const ProductForm: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData, handleSelectChange }) => {
  const [units, setUnits] = React.useState<Units[]>([]);
  const [productGroups, setProductGroups] = React.useState<ProductsGroups[]>([]);
  const [ledgerCodes, setLedgerCodes] = React.useState<LedgerCode[]>([]);

  React.useEffect(() => {
    const getUnits = async () => {
      const unitsResponse = await unitsByUser();
      if (unitsResponse) {
        setUnits(unitsResponse.data);
      }
    };

    const getProductGroups = async () => {
      const productGroupsResponse = await productGroupsByUser();
      if (productGroupsResponse) {
        setProductGroups(productGroupsResponse.data);
      }
    };

    const getLedgerCodes = async () => {
      const allLedgerCodes = await ledgerCodesByUser();
      if (allLedgerCodes) {
        setLedgerCodes(allLedgerCodes.data);
      }
    };

    getUnits();
    getProductGroups();
    getLedgerCodes();
  }, []);

  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataObject = event.target.dataset;

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        [dataObject.key as string]: event.target.checked,
      });
    }
  };

  return (
    <>
      <AdvancedInvoiceLines economicUserDefaultData={economicUserDefaultData} handleFormCheckChange={handleFormCheckChange} />

      <DefaultProductGroup economicUserDefaultData={economicUserDefaultData} productGroups={productGroups} handleSelectChange={handleSelectChange} />

      <DefaultProductUnit economicUserDefaultData={economicUserDefaultData} units={units} handleSelectChange={handleSelectChange} />

      <LedgerCodes
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        productGroups={productGroups}
        ledgerCodes={ledgerCodes}
      />

      <CrewProductLedgerCode
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        ledgerCodes={ledgerCodes}
      />

      {economicUserDefaultData.crew_ledger_code && (
        <CrewProductUnit economicUserDefaultData={economicUserDefaultData} units={units} handleSelectChange={handleSelectChange} />
      )}

      <hr className="mt-5" />

      <InvoiceLinesPriorities
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        ledgerCodes={ledgerCodes}
      />
    </>
  );
};
