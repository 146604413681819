import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { ReferenceOptions } from './types';

export const EconomicExternalId: React.FC<ReferenceOptions> = ({ economicUserDefaultData, setEconomicUserDefaultData, referenceOptions }) => {
  const handleExternalIdValueChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        external_id_value: event.target.value,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
      <Form.Label column sm={6}>
        Select value for external id
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectExternalIdValue" label="Select External ID">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.external_id_value} onChange={handleExternalIdValueChange}>
            {referenceOptions('externalId')}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
