import React, { useEffect } from 'react';
import { InvoiceData, InvoiceTabProps } from './types';
import { resyncInvoice } from '@src/Api';
import { Badge, Button, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InvoiceTab: React.FC<InvoiceTabProps> = ({ invoiceData, loading, setToasts, onSortChange }) => {
  const [activeInvoiceIds, setActiveInvoiceIds] = React.useState<number[]>([]);
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = React.useState<keyof InvoiceData | null>(null);

  const handleResyncClick = async (invoiceId: number): Promise<void> => {
    setActiveInvoiceIds((prevActiveInvoiceIds) => [...prevActiveInvoiceIds, invoiceId]);

    const response = await resyncInvoice(invoiceId);

    let responseData = '';
    if (response?.status === 200) {
      let data = response.data[0];
      if (!data) {
        data = response.data;
      }

      responseData = data.message;

      setToasts((prevToasts) => [...prevToasts, { id: invoiceId, message: responseData }]);
    }

    if (response?.status === 400) {
      let data = response.data[0];
      if (!data) {
        data = response.data;
      }

      responseData = data.hint;

      setToasts((prevToasts) => [...prevToasts, { id: invoiceId, message: responseData }]);
    }

    setActiveInvoiceIds((prevActiveInvoiceIds) => prevActiveInvoiceIds.filter((id) => id !== invoiceId));
  };

  const handleSort = (field: keyof InvoiceData) => {
    if (field === sortField) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  useEffect(() => {
    if (sortField && sortOrder) {
      onSortChange(sortField, sortOrder);
    }
  }, [sortField, sortOrder]);

  const getInvoiceStatus = (status: number): string => {
    let statusText: string;

    if (status === 0) {
      statusText = 'Failed';
    } else if (status === 1) {
      statusText = 'Completed';
    } else if (status === 3) {
      statusText = 'Skipped';
    } else {
      statusText = 'Processing';
    }

    return statusText;
  };

  const getStatusClasses = (status: number) => {
    let classes: string;

    if (status === 0) {
      classes = 'sync-overview-table-failed';
    } else if (status === 1) {
      classes = 'sync-overview-table-success';
    } else {
      classes = 'sync-overview-table-warning';
    }

    return classes;
  };

  return (
    <Table responsive className="sync-overview-table">
      <thead>
        <tr>
          <th onClick={() => handleSort('invoices_created')}>
            <div className="sync-overview-table-header">
              Timestamp
              <div>
                {sortField === 'invoices_created' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'invoices_created' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('invoices_id')}>
            <div className="sync-overview-table-header">
              Invoice ID
              <div>
                {sortField === 'invoices_id' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'invoices_id' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('invoices_number')}>
            <div className="sync-overview-table-header">
              Invoice number
              <div>
                {sortField === 'invoices_number' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'invoices_number' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('invoices_status')}>
            <div className="sync-overview-table-header">
              Invoice status
              <div>
                {sortField === 'invoices_status' && (sortOrder === 'asc' ? <FontAwesomeIcon icon="sort-up" /> : <FontAwesomeIcon icon="sort-down" />)}
                {sortField !== 'invoices_status' && <FontAwesomeIcon icon="sort" />}
              </div>
            </div>
          </th>
          <th className="sync-overview-table-header-action">
            <div className="sync-overview-table-header">Action</div>
          </th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={5} className="table-loader">
              <Spinner className="data-loading-spinner"></Spinner>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {invoiceData.length !== 0 &&
            invoiceData.map((invoice) => (
              <tr key={invoice.invoices_id}>
                <td>{new Date(invoice.invoices_created).toLocaleString('da-DK')}</td>
                <td>{invoice.invoices_id}</td>
                <td>{invoice.invoices_number}</td>
                <td>
                  <Badge pill className={getStatusClasses(invoice.invoices_status)}>
                    {getInvoiceStatus(invoice.invoices_status)}
                  </Badge>
                </td>
                <td>
                  {activeInvoiceIds.includes(invoice.invoices_id) ? (
                    <Button className="sync-overview-table-btn">
                      <Spinner className="" animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  ) : (
                    <Button className="sync-overview-table-btn" onClick={() => handleResyncClick(invoice.invoices_id)}>
                      <FontAwesomeIcon icon="arrows-rotate" />
                      <span>Re-sync data</span>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </Table>
  );
};
