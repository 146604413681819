import React, { createContext, useReducer, useEffect, useState, useContext } from 'react';
import { getLocalJwtToken, removeLocalJwtToken, setLocalJwtToken, decodeJwtToken, tokenExpired, getTokenExpiryTime } from '@src/lib/jwt';
import { UsersActions } from './types';

import type { UsersAction, UsersContextInterface } from './types';
import type { Children } from '@src/types';

const defaultState = {
  user: {
    id: 0,
    company: '',
    email: '',
    role: [''],
    exp: 0,
    rentmanConnected: false,
    economicConnected: false,
  },
  token: '',
};

export const userReducer = (usersPayload: UsersContextInterface, action: UsersAction): UsersContextInterface => {
  const { type, payload } = action;
  switch (type) {
    case UsersActions.LOGIN: {
      console.log('payload: ', payload);

      setLocalJwtToken(payload);
      const jwtPayload = decodeJwtToken(payload);

      const decodedCompany = decodeURIComponent(escape(jwtPayload.company));

      return {
        ...usersPayload,
        token: payload,
        user: {
          ...jwtPayload,
          company: decodedCompany,
        },
      };
    }
    case UsersActions.LOGOUT: {
      removeLocalJwtToken();

      return {
        ...usersPayload,
        ...defaultState,
      };
    }
    default:
      return usersPayload;
  }
};

export const UsersContext = createContext<{
  usersState: UsersContextInterface;
  usersDispatch: React.Dispatch<UsersAction>;
}>({
  usersState: defaultState,
  usersDispatch: () => null,
});

export const UsersContextProvider: React.FC<Children> = ({ children }) => {
  const [usersState, usersDispatch] = useReducer(userReducer, defaultState);
  const [expiryTime, setExpiryTime] = useState<number | null>(null);

  const handleJwtToken = () => {
    const localJwt = getLocalJwtToken();

    if (localJwt) {
      const isExpired = tokenExpired(localJwt);
      if (isExpired === true) {
        usersDispatch({ type: UsersActions.LOGOUT, payload: '' });
      } else {
        usersDispatch({ type: UsersActions.LOGIN, payload: localJwt });

        const expiry = getTokenExpiryTime(localJwt);
        setExpiryTime(expiry);
      }
    }
  };

  useEffect(() => {
    handleJwtToken();

    const intervalId = setInterval(() => {
      handleJwtToken();
    }, 300000); // Check every 5 minutes

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  useEffect(() => {
    if (expiryTime) {
      const timeoutId = setTimeout(
        () => {
          handleJwtToken();
        },
        expiryTime - Date.now() - 1000,
      ); // Recheck 1 second before expiry

      return () => clearTimeout(timeoutId); // Cleanup on unmount or expiryTime change
    }
  }, [expiryTime]);

  return <UsersContext.Provider value={{ usersState, usersDispatch }}>{children}</UsersContext.Provider>;
};

export const useUsersContext = (): {
  usersState: UsersContextInterface;
  usersDispatch: React.Dispatch<UsersAction>;
} => {
  return useContext(UsersContext);
};
