import React from 'react';
import { useUsersContext } from '@src/contexts/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { DataProcessingAgreementPage } from '../terms/data-processing-agreement';
import { TermsAndConditionsPage } from '../terms/terms-and-conditions';

export const AccountPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const rentmanStatus = usersState.user.rentmanConnected;
  const economicStatus = usersState.user.economicConnected;
  console.log(usersState);

  return (
    <>
      <Row className="account">
        <Col className="p-0">
          <div className="line-tabs mt-4">
            <Tabs defaultActiveKey="accountInfo" id="account-tabs">
              <Tab eventKey="accountInfo" title="Account information">
                <div className="account-content">
                  <p>
                    <strong>ID: </strong>
                    {usersState.user.id}
                  </p>
                  <p>
                    <strong>Company: </strong>
                    {usersState.user.company}
                  </p>
                  <p>
                    <strong>Email: </strong>
                    {usersState.user.email}
                  </p>
                  <p>
                    <strong>Password: </strong>******
                  </p>
                  <p>
                    <strong>Role: </strong>
                    {usersState.user.role[0] === 'admin' ? 'Administrator' : 'Bruger'}
                  </p>
                  <p className={rentmanStatus ? 'success' : 'failed'}>
                    <strong>Rentman connection: </strong>
                    {rentmanStatus ? <FontAwesomeIcon icon="circle-check" /> : <FontAwesomeIcon icon="circle-xmark" />}
                  </p>
                  <p className={economicStatus ? 'success' : 'failed'}>
                    <strong>E-conomic connection: </strong>
                    {economicStatus ? <FontAwesomeIcon icon="circle-check" /> : <FontAwesomeIcon icon="circle-xmark" />}
                  </p>
                </div>
              </Tab>
              <Tab eventKey="dataProcessingAgreement" title="Data Processing Agreement">
                <DataProcessingAgreementPage />
              </Tab>
              <Tab eventKey="termsAndConditions" title="Terms and conditions">
                <TermsAndConditionsPage />
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </>
  );
};
