import React, { useState, useEffect } from 'react';
// User context
import { useUsersContext } from '@src/contexts/users';
import { UsersActions } from '@src/contexts/users/types';
// Types
import type { UsersIntergrationType, ErrorObject } from './types';
import type { AxiosResponse } from 'axios';
// API
import { setAllUsersIntegration, testConnection } from '@src/Api';
// Styles
import { Row, Col, Container, Card } from 'react-bootstrap';
// Components
import RentmanForm from './rentman';
import EconomicForm from './economic';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { CustomToast } from '@src/components/toast';
import EconomicUserToken from './economicUserToken';

const defaultConfig = {
  users_integrations_type: '',
  users_integrations_api_data: {
    apiKey: '',
    apiSecret: '',
    apiHookKey: '',
  },
  users_integrations_connected: '',
};

export const UserConfigPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as { error: string } | undefined;
  const [showToast, setShowToast] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState<{ rentman: boolean; economic: boolean }>({ rentman: false, economic: false });
  const [submissionStatus, setSubmissionStatus] = useState<{ rentman: boolean; economic: boolean }>({ rentman: false, economic: false });
  const [validationError, setValidationError] = useState<{ [fieldId: string]: boolean }>({});
  const { usersDispatch } = useUsersContext();
  const [config, setConfig] = useState<UsersIntergrationType>({
    rentman: { ...defaultConfig },
    economic: { ...defaultConfig },
  });
  const [statusText, setStatusText] = useState<{ [x: string]: string }>({
    rentman: '',
    economic: '',
  });
  const [loading, setLoading] = useState<{ [x: string]: boolean }>({
    rentman: false,
    economic: false,
  });
  const [errorObject, setErrorObject] = useState<ErrorObject>({
    rentman: null,
    economic: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleResponse = async (response: AxiosResponse | null, type: 'rentman' | 'economic'): Promise<void> => {
    console.log('Response: ', response);

    if (!response || typeof response.data !== 'object') {
      setStatusText({
        ...statusText,
        [type]: 'Error: No valid response received.',
      });
      setSubmissionSuccess((prevState) => ({ ...prevState, [type]: false }));
      setSubmissionStatus((prevState) => ({ ...prevState, [type]: false }));
      return;
    }

    if (response && typeof response.data === 'object') {
      if ('error' in response.data) {
        setStatusText({
          ...statusText,
          [type]: ` ${response.status}: ${response.data.status ? response.data.status : response.data.error}`,
        });
        setErrorObject({
          ...errorObject,
          [type]: response.data.error as object,
        });
        setSubmissionSuccess((prevState) => ({ ...prevState, [type]: false }));
        setSubmissionStatus((prevState) => ({ ...prevState, [type]: false }));
      } else {
        setStatusText({
          [type]: ` ${response.status}: ${response.data.message}`,
        });
        setErrorObject({
          ...errorObject,
          [type]: null,
        });
        setSubmissionSuccess((prevState) => ({ ...prevState, [type]: true }));
        setSubmissionStatus((prevState) => ({ ...prevState, [type]: true }));
      }

      if (response.data.integration) {
        setConfig((prevConfig) => ({
          ...prevConfig,
          [type]: response.data.integration,
        }));
      }

      if (response.data.token) {
        usersDispatch({ type: UsersActions.LOGIN, payload: response.data.token });
      }
    }
  };

  useEffect(() => {
    if (state && state.error) {
      setShowToast(true);
    }
  }, [state]);

  useEffect(() => {
    const getIntegrationHandler = async (): Promise<void> => {
      const testRentmanConnectionResponse = await testConnection('rentman');
      await handleResponse(testRentmanConnectionResponse, 'rentman');

      const testEconomicConnectionResponse = await testConnection('economic');
      await handleResponse(testEconomicConnectionResponse, 'economic');
    };
    getIntegrationHandler();
  }, []);

  const handleRentmanValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      rentman: {
        ...config.rentman,
        users_integrations_api_data: {
          ...config.rentman.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleEconomicValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      economic: {
        ...config.economic,
        users_integrations_api_data: {
          ...config.economic.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleValidationError = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fieldId = event.target.name;
    setValidationError({
      ...validationError,
      [fieldId]: value.trim() === '',
    });
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>, type: 'rentman' | 'economic'): Promise<void> => {
    e.preventDefault();

    setLoading({
      [type]: true,
    });

    const response = await setAllUsersIntegration(config, type);
    await handleResponse(response, type);

    setLoading({
      [type]: false,
    });

    setFormSubmitted(true);
  };

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          <CustomToast
            duration={6000}
            show={showToast}
            title="Error"
            message={location?.state?.error || 'An unknown error occurred'}
            type="error"
            onClose={() => setShowToast(false)}
          />
        </AnimatePresence>
      </Container>
      <Row className="connection">
        <Col className="p-0">
          <div>
            <h1 className="connection-header">Connections</h1>
            <hr className="mt-2" />
          </div>
          <div className="mt-5 mb-5">
            <RentmanForm
              config={config}
              loading={loading}
              statusText={statusText}
              errorObject={errorObject}
              formSubmitted={formSubmitted}
              validationError={validationError}
              handleValidationError={handleValidationError}
              handleRentmanValueChanged={handleRentmanValueChanged}
              handleEconomicValueChanged={handleEconomicValueChanged}
              handleFormSubmit={handleFormSubmit}
              submissionSuccess={submissionSuccess}
              setSubmissionSuccess={setSubmissionSuccess}
              submissionStatus={submissionStatus}
            />
          </div>

          <Card className="connection-card connection-card-economic d-flex flex-row justify-content-between p-0">
            <EconomicForm
              config={config}
              loading={loading}
              statusText={statusText}
              errorObject={errorObject}
              formSubmitted={formSubmitted}
              validationError={validationError}
              handleValidationError={handleValidationError}
              handleEconomicValueChanged={handleEconomicValueChanged}
              handleRentmanValueChanged={handleEconomicValueChanged}
              handleFormSubmit={handleFormSubmit}
              submissionSuccess={submissionSuccess}
              setSubmissionSuccess={setSubmissionSuccess}
              submissionStatus={submissionStatus}
            />
            {config.economic.users_integrations_api_data.economicUserToken && <EconomicUserToken config={config} />}
          </Card>
        </Col>
      </Row>
    </>
  );
};
