import React from 'react';

import { EconomicReferenceTitle } from './title';
import { EconomicReferenceText1 } from './text_1';
import { EconomicReferenceText2 } from './text_2';

import type { ReferenceOptions } from '../types';

export const EconomicReference: React.FC<ReferenceOptions> = ({ economicUserDefaultData, setEconomicUserDefaultData, referenceOptions }) => {
  return (
    <>
      <EconomicReferenceTitle
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        referenceOptions={referenceOptions}
      />

      <EconomicReferenceText1
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        referenceOptions={referenceOptions}
      />

      <EconomicReferenceText2
        economicUserDefaultData={economicUserDefaultData}
        setEconomicUserDefaultData={setEconomicUserDefaultData}
        referenceOptions={referenceOptions}
      />
    </>
  );
};
