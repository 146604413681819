import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CopyToClipboardButtonProps } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <>
      <Button className="copy-btn" size="sm" onClick={handleCopyToClipboard} disabled={copied}>
        <FontAwesomeIcon icon={'copy'} />
        {copied ? <span>Copied!</span> : <span>Copy</span>}
      </Button>
    </>
  );
};

export default CopyToClipboardButton;
