import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const RentmanUploadInvoicePdf: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  uploadPdfError,
  setUploadPdfError,
}) => {
  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      upload_pdf: event.target.checked,
    });
  };

  const handlePdfTypeChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    if (setUploadPdfError) {
      setUploadPdfError(null);
    }

    const option = event.target.options[event.target.selectedIndex];

    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      upload_pdf_type: `${option.value}`,
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
        <Form.Label column sm={6}>
          Enable PDF upload
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="pdf-upload-switch"
            label={rentmanUserDefaultData.upload_pdf ? 'Enabled' : 'Disabled'}
            checked={rentmanUserDefaultData.upload_pdf ? rentmanUserDefaultData.upload_pdf : false}
            onChange={handleFormCheckChange}
          />
        </Col>
      </Form.Group>
      {rentmanUserDefaultData.upload_pdf && rentmanUserDefaultData.upload_pdf === true && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceProjectId">
          <Form.Label column sm={6}>
            Select upload of quotation or contract PDF
          </Form.Label>
          <Col sm={6}>
            <FloatingLabel controlId="floatingInputInvoiceProjectId" label="Select PDF">
              <Form.Select
                aria-label="Floating label select"
                className={uploadPdfError && rentmanUserDefaultData.upload_pdf === true ? 'error-field' : ''}
                value={rentmanUserDefaultData.upload_pdf_type}
                onChange={handlePdfTypeChange}
              >
                <option hidden value="">
                  Open this select menu
                </option>
                <option key="uploadType-contracts" value="contracts">
                  Contract
                </option>
                <option key="uploadType-quotes" value="quotes">
                  Quote
                </option>
                <option key="uploadType-invoice" value="invoices">
                  Invoice
                </option>
                <option key="uploadType-signedContracts" value="signedContracts">
                  Signed Contract
                </option>
                <option key="uploadType-signedQuotes" value="signedQuotes">
                  Signed Quote
                </option>
              </Form.Select>
            </FloatingLabel>
            <div className="error-container-settings">
              <p className={uploadPdfError ? 'error-text' : ''}>{uploadPdfError}</p>
            </div>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
