import { useState, useEffect } from 'react';
import { validateFields } from '@src/utils/validation/validateFields/validateFields';

export const usePasswordValidation = (initialPassword: string, initialConfirmPassword: string) => {
  const [password, setPassword] = useState(initialPassword);
  const [confirmPassword, setConfirmPassword] = useState(initialConfirmPassword);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
  const [mismatchError, setMismatchError] = useState<string | null>(null);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  useEffect(() => {
    const { passwordError, confirmPasswordError, mismatchError } = validateFields({
      newPassword: password,
      confirmNewPassword: confirmPassword,
    });

    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);
    setMismatchError(mismatchError);
  }, [password, confirmPassword, confirmPasswordTouched, passwordTouched]);

  return {
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    mismatchError,
    setPassword,
    setConfirmPassword,
    passwordTouched,
    setPasswordTouched,
    confirmPasswordTouched,
    setConfirmPasswordTouched,
  };
};
