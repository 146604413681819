import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const RentmanSyncState: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  invoiceTypeError,
  setInvoiceTypeError,
}) => {
  const handleInvoiceEventTypeChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setInvoiceTypeError) {
      setInvoiceTypeError(null);
    }

    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      invoice_event_type: `${option.value}`,
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanInvoiceStatus">
        <Form.Label column sm={6}>
          Specify when to transfer invoices to Rentman
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanInvoiceStatus" label="Select when to transfer invoice">
            <Form.Select
              className={invoiceTypeError ? 'error-field' : ''}
              aria-label="Floating label select"
              value={rentmanUserDefaultData.invoice_event_type}
              onChange={handleInvoiceEventTypeChange}
            >
              <option hidden value="">
                Open this select menu
              </option>

              <option key={`rentmanInvoiceStatus-1`} value="create">
                Create invoice
              </option>

              <option key={`rentmanInvoiceStatus-2`} value="update">
                Update / Finalize invoice
              </option>
            </Form.Select>
          </FloatingLabel>
          <div className="error-container-settings">
            <p className={invoiceTypeError ? 'error-text' : ''}>{invoiceTypeError}</p>
          </div>
        </Col>
      </Form.Group>
    </>
  );
};
