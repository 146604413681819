import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicInternationalFactors: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleEuFactorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (setEconomicUserDefaultData) {
      if (value === '') {
        setEconomicUserDefaultData({
          ...economicUserDefaultData,
          factor_eu: undefined,
        });
      } else {
        const parsed = parseFloat(value);
        if (!isNaN(parsed)) {
          setEconomicUserDefaultData({
            ...economicUserDefaultData,
            factor_eu: parsed,
          });
        }
      }
    }
  };

  const handleOutsideEuFactorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (setEconomicUserDefaultData) {
      if (value === '') {
        setEconomicUserDefaultData({
          ...economicUserDefaultData,
          factor_outside_eu: undefined,
        });
      } else {
        const parsed = parseFloat(value);
        if (!isNaN(parsed)) {
          setEconomicUserDefaultData({
            ...economicUserDefaultData,
            factor_outside_eu: parsed,
          });
        }
      }
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmployeeGroup">
        <Form.Label column sm={6}>
          Specify factor for EU customers
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputEUFactor" label="Factor">
            <Form.Control type="number" step="any" value={economicUserDefaultData.factor_eu ?? ''} onChange={handleEuFactorChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmployeeGroup">
        <Form.Label column sm={6}>
          Specify factor for customers outside the EU
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputOutsideEUFactor" label="Factor">
            <Form.Control type="number" step="any" value={economicUserDefaultData.factor_outside_eu ?? ''} onChange={handleOutsideEuFactorChange} />
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
