import { EconomicSettingModuleProps } from '../../types';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

export const EconomicTransferCoC: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleTransferCoCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        transfer_coc: event.target.checked,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmailUpload">
      <Form.Label column sm={6}>
        Enable use of CoC number for EAN number
      </Form.Label>
      <Col sm={6}>
        <Form.Check
          type="switch"
          id="transfer-coc-switch"
          label={economicUserDefaultData.transfer_coc ? 'Enabled' : 'Disabled'}
          checked={economicUserDefaultData.transfer_coc ? economicUserDefaultData.transfer_coc : false}
          onChange={handleTransferCoCChange}
        />
      </Col>
    </Form.Group>
  );
};
