import React from 'react';
import { Accordion } from 'react-bootstrap';
import { syntaxHighlight } from '../../components/syntax-highlight';
import { FailureDetail, LogsData } from './types';
import FoldableFailure from './foldableFailure';

interface ModalComponentProps {
  selectedLog: LogsData | null;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ selectedLog }) => {
  const logContent = selectedLog
    ? `
Log ID: ${selectedLog.logs_id}
Invoice ID: ${selectedLog.logs_invoice_id}
Type: ${selectedLog.logs_type}
Event: ${selectedLog.logs_message}
Status: ${selectedLog.logs_status}
Timestamp: ${new Date(selectedLog.logs_created).toLocaleString('da-DK')}
    `
    : '';

  const highlightedResponse = selectedLog ? syntaxHighlight(JSON.stringify(selectedLog.logs_response, undefined, 4)) : '';

  const failureResponse = selectedLog?.logs_response as { failures: FailureDetail[] };
  const failures = failureResponse?.failures ?? [];

  return (
    <>
      <div>
        <Accordion defaultActiveKey={['0']} alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Response</Accordion.Header>
            <Accordion.Body>
              {selectedLog && (
                <div className="log-table-response">
                  <pre>{logContent}</pre>
                  <p>Log response:</p>
                  {failures.length > 0 ? (
                    failures.map((failure, index) => <FoldableFailure key={index} failure={{ ...failure, isOpen: false }} syntaxHighlight={syntaxHighlight} />)
                  ) : (
                    <pre dangerouslySetInnerHTML={{ __html: highlightedResponse }} />
                  )}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default ModalComponent;
