import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useUsersContext } from '@src/contexts/users';
import { Container } from 'react-bootstrap';
import { CustomButton } from '@src/components/custom-button';

//Images
import setupGuideImage from '@public/img/setup-guide.svg';
import settingsImage from '@public/img/settings.svg';
import connectionsImage from '@public/img/connections.svg';
import rentmanEconomic from '@public/img/rentman-economic-combo.svg';

export const DashboardPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const isApiConnected = (): boolean => {
    if (usersState.user.rentmanConnected) {
      return usersState.user.rentmanConnected;
    }

    return false;
  };

  return (
    <>
      <Row className="dashboard">
        <Col className="p-0">
          <Row className="dashboard-main">
            <h1 className="dashboard-main-title">Hi {usersState.user.company}! </h1>
            <div>
              <b>Welcome to the integration for Rentman and E-conomic</b>
              <p>Here you can view general information regarding the integration</p>
            </div>
            <Row className="dashboard-main-image">
              <img src={`${rentmanEconomic}`} alt="RentmanEconomic"></img>
            </Row>
            <Col className="col-6"></Col>
          </Row>

          <Container className="dashboard-custom-button">
            <Row>
              <p className="dashboard-custom-button-header">Get Started!</p>
            </Row>
            <Row>
              <Col className="dashboard-custom-button-container">
                <CustomButton description={'Start setup here!'} title={'Setup Guide'} image={setupGuideImage} href="/setup-guide" />
              </Col>
              <Col className="dashboard-custom-button-container">
                <CustomButton description={'Go to connections here!'} title={'Connections'} image={connectionsImage} href="/connection-config" />
              </Col>
              {isApiConnected() === true && (
                <Col className="dashboard-custom-button-container">
                  <CustomButton description={'Go to settings here!'} title={'Settings'} image={settingsImage} href="/settings" />
                </Col>
              )}
            </Row>
          </Container>
          <Row className="mt-5">
            <Col className="text-center">
              <a href="https://www.itstack.dk/handelsbetingelser/" target="_blank" rel="noreferrer">
                Terms for integration
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
