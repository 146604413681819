import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { layoutsByUser } from '@src/Api';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import { Layouts } from '../../invoice/types';

export const EconomicInternationalLayouts: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, handleSelectChange }) => {
  const [layouts, setLayouts] = React.useState<Layouts[]>([]);

  React.useEffect(() => {
    const getLayouts = async () => {
      const layoutsResponse = await layoutsByUser();
      if (layoutsResponse) {
        setLayouts(layoutsResponse.data);
      }
    };

    getLayouts();
  }, []);

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceLayout">
        <Form.Label column sm={6}>
          Specify invoice layout for customers in the EU
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectInvoiceLayout" label="Select invoice layout">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.layout_eu?.layoutNumber} onChange={handleSelectChange}>
              <option hidden value="" data-key={'layout'}>
                Open this select menu
              </option>
              {layouts.length !== 0 &&
                layouts.map((layout) => (
                  <option
                    key={`layout-${layout.layoutNumber}`}
                    value={layout.layoutNumber}
                    data-self={layout.self}
                    data-key={'layout_eu'}
                    data-numberkey={'layoutNumber'}
                    data-selfkey={'self'}
                  >
                    {layout.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceLayout">
        <Form.Label column sm={6}>
          Specify invoice layout for customers outside the EU
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectInvoiceLayout" label="Select invoice layout">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.layout_outside_eu?.layoutNumber} onChange={handleSelectChange}>
              <option hidden value="" data-key={'layout'}>
                Open this select menu
              </option>
              {layouts.length !== 0 &&
                layouts.map((layout) => (
                  <option
                    key={`layout-${layout.layoutNumber}`}
                    value={layout.layoutNumber}
                    data-self={layout.self}
                    data-key={'layout_outside_eu'}
                    data-numberkey={'layoutNumber'}
                    data-selfkey={'self'}
                  >
                    {layout.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
