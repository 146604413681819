import fetcher from '@src/lib/fetcher';
import type { AxiosResponse } from 'axios';
import type { UsersIntergrationType } from './pages/connection/types';
import type { EconomicUserSettings, RentmanUserSettings } from './pages/settings/types';
import { UserProps } from './pages/dashboard/types';

//User calls

//Get all users
export const getUsers = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/get-users`, 'GET', false);
};

//Get user by user ID
export const getUserByUserId = async (userId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/get-user-by-id`, 'GET', false, {
    userId,
  });
};

//Delete user
export const deleteAccount = async (userId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/delete-user`, 'DELETE', false, {
    userId,
  });
};

//Create user
export const createAccount = async (email: string, company: string, password: string, role: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/create-account`, 'POST', false, {
    email,
    company,
    password,
    role,
  });
};

//Update user
export const updateUser = async (userId: number, email: string, company: string, role: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/update-user`, 'PUT', false, {
    userId,
    email,
    company,
    role,
  });
};

//Login user
export const login = async (email: string, password: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/login`, 'POST', false, {
    email,
    password,
  });
};

//Login user as admin
export const loginAsUser = async (user: UserProps, token: string): Promise<AxiosResponse | null> => {
  return await fetcher(
    `${process.env.REACT_APP_API_URL}/users/login-as-user`,
    'POST',
    false,
    {
      user,
    },
    { Authorization: `Bearer ${token}` },
  );
};

//Forgot password
export const forgotPass = async (email: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/forgot-password`, 'POST', false, { email });
};

//Update password
export const updatePassword = async (token: string, newPassword: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/reset-password`, 'POST', false, { token, newPassword });
};

//Verify password reset token
export const verifyResetToken = async (token: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/verify-reset-token`, 'POST', false, { token });
};

//Users integration
export const integrationByType = async (type: 'rentman' | 'economic', selecting?: 'all' | 'api' | 'data'): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/filter/${type}${selecting ? `?selecting=${selecting}` : ''}`, 'GET', false);
};

export const allUsersIntegration = async (selecting?: 'all' | 'api' | 'data'): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/all${selecting ? `?selecting=${selecting}` : ''}`, 'GET', false);
};

export const setAllUsersIntegration = async (config: UsersIntergrationType, type: 'rentman' | 'economic'): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/create/${type}`, 'POST', false, config);
};

export const updateUserIntegration = async (type: string, data: EconomicUserSettings | RentmanUserSettings): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/update/default/data`, 'PUT', false, {
    type,
    data: { users_integrations_default_data: data },
  });
};

// Rentman
export const getUserIntegrationByUserIdAndType = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentman/test`, 'GET', false);
};

export const stockLocationsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentman/stock/locations/all`, 'GET', false);
};

export const ledgerCodesByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentman/ledger/codes/all`, 'GET', false);
};

// E-conomic
export const allCurrencies = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/currencies/all`, 'GET', false);
};

export const currenciesBySearch = async (search: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/currencies/filter/${search}`, 'GET', false);
};

export const customerGroupsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/customer/groups/all`, 'GET', false);
};

export const employeeGroupsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/employee/groups/all`, 'GET', false);
};

export const layoutsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/layouts/all`, 'GET', false);
};

export const paymentTermsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/payment/terms/all`, 'GET', false);
};

export const productGroupsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/products/groups/all`, 'GET', false);
};

export const unitsByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/units/all`, 'GET', false);
};

export const vatZonesByUser = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/vat/zones/all`, 'GET', false);
};

// Invoices
export const allInvoices = async (limit?: number, page?: number, sortOrder?: 'asc' | 'desc', sortField?: string): Promise<AxiosResponse | null> => {
  const queryParams: Record<string, string> = {};

  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  if (sortField) {
    queryParams.sortField = sortField;
  }

  // Initialize the URL
  const baseUrl = `${process.env.REACT_APP_API_URL}/invoices/all`;
  const urlObj = new URL(`${baseUrl}${queryString}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

export const searchedInvoices = async (
  limit?: number,
  page?: number,
  searchQuery?: string,
  sortOrder?: 'asc' | 'desc',
  sortField?: string,
): Promise<AxiosResponse | null> => {
  const path = limit || page ? `/search/${limit || ''}/${page || ''}` : '/search';

  const queryParams: Record<string, string> = {};

  if (searchQuery) {
    queryParams.search = searchQuery;
  }

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  if (sortField) {
    queryParams.sortField = sortField;
  }

  // Initialize the URL
  const baseUrl = `${process.env.REACT_APP_API_URL}/invoices`;
  const urlObj = new URL(`${baseUrl}${path}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

export const resyncInvoice = async (invoiceId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentman/resync`, 'POST', false, { invoiceId });
};

//Payments
export const allPayments = async (limit?: number, page?: number, sortOrder?: 'asc' | 'desc', sortField?: string): Promise<AxiosResponse | null> => {
  const queryParams: Record<string, string> = {};

  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  if (sortField) {
    queryParams.sortField = sortField;
  }
  // Initialize the URL
  const baseUrl = `${process.env.REACT_APP_API_URL}/payments/all`;
  const urlObj = new URL(`${baseUrl}${queryString}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

export const resyncPayment = async (entryId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/resync`, 'POST', false, { entryId });
};

export const getPaymentSetting = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/economic/payment-setting`, 'GET', false);
};

export const searchedPayments = async (
  limit?: number,
  page?: number,
  searchQuery?: string,
  sortOrder?: 'asc' | 'desc',
  sortField?: string,
): Promise<AxiosResponse | null> => {
  const path = limit || page ? `/search/${limit || ''}/${page || ''}` : '/search';

  const queryParams: Record<string, string> = {};

  if (searchQuery) {
    queryParams.search = searchQuery;
  }

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  if (sortField) {
    queryParams.sortField = sortField;
  }

  // Initialize the URL
  const baseUrl = `${process.env.REACT_APP_API_URL}/payments`;
  const urlObj = new URL(`${baseUrl}${path}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

// Logs
// Invoices
export const allLogs = async (limit?: number, page?: number, sortOrder?: 'asc' | 'desc', sortField?: string): Promise<AxiosResponse | null> => {
  const queryParams: Record<string, string> = {};

  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  if (sortField) {
    queryParams.sortField = sortField;
  }

  const baseUrl = `${process.env.REACT_APP_API_URL}/logs/all`;
  const urlObj = new URL(`${baseUrl}${queryString}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

export const searchedLogs = async (
  limit?: number,
  page?: number,
  searchQuery?: string,
  selectedType?: string,
  sortOrder?: 'asc' | 'desc',
  sortField?: string,
): Promise<AxiosResponse | null> => {
  const path = limit || page ? `/search/${limit || ''}/${page || ''}` : '/search';

  const queryParams: Record<string, string> = {};

  if (searchQuery) {
    queryParams.search = searchQuery;
  }
  if (selectedType) {
    queryParams.type = selectedType;
  }

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  if (sortField) {
    queryParams.sortField = sortField;
  }

  // Initialize the URL
  const baseUrl = `${process.env.REACT_APP_API_URL}/logs`;
  const urlObj = new URL(`${baseUrl}${path}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

// Test Connection
export const testConnection = async (type: 'rentman' | 'economic'): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/${type}/connection/test`, 'GET', false);
};
