import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { EconomicSettingModuleProps } from '../../types';
import React from 'react';

export const PaymentForm: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handlePaymentSyncValueChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        payment_sync_enabled: event.target.checked,
      });
    }
  };

  const handleInvoiceNumberFieldChanged = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        invoice_number_field: event.target.value,
      });
    }
  };

  return (
    <Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={6}>
          <Form.Label>Enable payment sync to Rentman</Form.Label>
        </Col>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="payment-sync-switch"
            label={economicUserDefaultData.payment_sync_enabled ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.payment_sync_enabled ? economicUserDefaultData.payment_sync_enabled : false}
            onChange={handlePaymentSyncValueChange}
          />
        </Col>
      </Form.Group>
      {economicUserDefaultData.payment_sync_enabled && economicUserDefaultData.payment_sync_enabled === true && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalInvoiceNumberField">
          <Form.Label column sm={6}>
            Specify which field to use for invoice number
          </Form.Label>
          <Col sm={6}>
            <FloatingLabel controlId="floatingSelectInvoiceNumberField" label="Select field">
              <Form.Select aria-label="Floating label select" value={economicUserDefaultData.invoice_number_field} onChange={handleInvoiceNumberFieldChanged}>
                <option hidden value="">
                  Open this select menu
                </option>
                <option data-key="externalId" value="externalId">
                  External ID
                </option>
                <option data-key="heading" value="heading">
                  Heading
                </option>
                <option data-key="textLine1" value="textLine1">
                  Text line 1
                </option>
                <option data-key="textLine2" value="textLine2">
                  Text line 2
                </option>
                <option data-key="otherReference" value="otherReference">
                  Other reference
                </option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Form.Group>
      )}
    </Form.Group>
  );
};
