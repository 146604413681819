import { createAccount } from '@src/Api';
import React, { useState } from 'react';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { CustomToast } from '@src/components/toast';
import { validateEmail } from '@src/utils/emailUtils';
import { usePasswordValidation } from '@src/utils/validation/usePasswordValidation';

export const CreateAccountPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [toastError, setToastError] = useState<string | null>(null);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isCompanyValid, setIsCompanyValid] = useState<boolean>(true);
  const [isUserRoleValid, setIsUserRoleValid] = useState<boolean>(true);
  const [role, setRole] = useState<string>('');

  const {
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    mismatchError,
    setPassword,
    setConfirmPassword,
    passwordTouched,
    confirmPasswordTouched,
    setPasswordTouched,
    setConfirmPasswordTouched,
  } = usePasswordValidation('', '');

  const handleAccountCreation = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    let allFieldsValid = true;

    if (email.trim() === '' || !isValidEmail) {
      setIsValidEmail(false);
      allFieldsValid = false;
    }

    if (company.trim() === '') {
      setIsCompanyValid(false);
      allFieldsValid = false;
    }

    if (password.trim() === '' || confirmPassword.trim() === '' || password !== confirmPassword) {
      allFieldsValid = false;
    }

    if (!role) {
      setIsUserRoleValid(false);
      allFieldsValid = false;
    }

    if (!allFieldsValid) return;

    if (password !== confirmPassword || !isValidEmail || !isCompanyValid || !role) {
      setIsUserRoleValid(!!role);
      return;
    }

    setLoading(true);
    try {
      const response = await createAccount(email, company, password, role);

      if (response?.data?.error) {
        setToastError(response.data.error);
      } else {
        setToastError(null);
        setPassword('');
        setConfirmPassword('');
        setCompany('');
        setEmail('');
        setRole('');
        setConfirmPasswordTouched(false);
        setPasswordTouched(false);
      }
      setShowToast(true);
    } catch (error) {
      console.error('Error creating account', error);
      alert('Failed to create account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="admin">
      <Col className="p-0">
        <div>
          <h1 className="admin-header">Create account</h1>
          <hr className="mt-2" />
        </div>
        <Form className="admin-form">
          <div className="admin-form-group">
            <Form.Group className="admin-form-input">
              <Form.Label>Company</Form.Label>
              <Form.Label className="required-star">*</Form.Label>
              <Form.Control
                placeholder="Enter company"
                type="text"
                value={company}
                className={`${isCompanyValid ? '' : 'input-error'}`}
                onBlur={() => {
                  setIsCompanyValid(company.trim() !== '');
                }}
                onChange={(e) => {
                  setCompany(e.target.value);
                  setIsCompanyValid(true);
                }}
                required
              />
              <div className="error-container">{!isCompanyValid && <p className="error-text">*Company field is required</p>}</div>
            </Form.Group>
            <Form.Group className="admin-form-input">
              <Form.Label>User Role</Form.Label>
              <Form.Label className="required-star">*</Form.Label>
              <Form.Select
                required
                size="sm"
                className={`${isUserRoleValid ? '' : 'input-error'}`}
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                  setIsUserRoleValid(true);
                }}
                onBlur={() => {
                  setIsUserRoleValid(role !== '');
                }}
              >
                <option value="" disabled>
                  Choose a Role
                </option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </Form.Select>
              <div className="error-container">{!isUserRoleValid && <p className="error-text">*User Role is required</p>}</div>
            </Form.Group>
          </div>
          <Form.Group className="admin-form-input">
            <Form.Label>Email</Form.Label>
            <Form.Label className="required-star">*</Form.Label>
            <Form.Control
              className={`${isValidEmail ? '' : 'input-error'}`}
              placeholder="Enter email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              onBlur={() => {
                const isValid = validateEmail(email);
                setIsValidEmail(isValid);
                if (!isValid) {
                  return;
                } else {
                  return;
                }
              }}
            />
            <div className="error-container">{!isValidEmail && <p className="error-text">*Please enter a valid email</p>}</div>
          </Form.Group>
          <Form.Group className="admin-form-input">
            <Form.Label>Password</Form.Label>
            <Form.Label className="required-star">*</Form.Label>
            <Form.Control
              className={`${(passwordError && passwordTouched) || (confirmPasswordTouched && passwordTouched && mismatchError) ? 'input-error' : ''}`}
              placeholder="Enter Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              onBlur={() => setPasswordTouched(true)}
              onFocus={() => setPasswordTouched(false)}
            />
            <div className="error-container">{passwordError && passwordTouched && <p className="error-text">*Password field is required</p>}</div>
          </Form.Group>
          <Form.Group className="admin-form-input">
            <Form.Label>Confirm password</Form.Label>
            <Form.Control
              className={`${
                (confirmPasswordError && confirmPasswordTouched) || (confirmPasswordTouched && passwordTouched && mismatchError) ? 'input-error' : ''
              }`}
              placeholder="Repeat password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              onBlur={() => setConfirmPasswordTouched(true)}
              onFocus={() => setConfirmPasswordTouched(false)}
            />
            <div className="error-container">
              {confirmPasswordError && confirmPasswordTouched && <p className="error-text">*Confirm password field is required</p>}
              {confirmPasswordTouched && passwordTouched && mismatchError && <p className="error-text">{mismatchError}</p>}
            </div>
          </Form.Group>
          <Row className="mt-2">
            <Col>
              {showToast && (
                <Container className="toast-b-right">
                  <CustomToast
                    duration={10000}
                    show={showToast}
                    title={toastError ? 'Error' : 'Account Created'}
                    message={toastError ? toastError : 'New Account was successfully created.'}
                    onClose={() => setShowToast(false)}
                    type={toastError ? 'error' : 'success'}
                  />
                </Container>
              )}
            </Col>
          </Row>
          {!loading ? (
            <Form.Group className="mt-1">
              <Button className="admin-form-btn" type="submit" onClick={(e) => handleAccountCreation(e)}>
                Create Account
              </Button>
            </Form.Group>
          ) : (
            <Spinner></Spinner>
          )}
        </Form>
      </Col>
    </Row>
  );
};
