import { Card } from 'react-bootstrap';
import { UsersIntergrationFormProps } from './types';
import React from 'react';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import CopyToClipboardButton from '@src/components/copy-clipboard';

const EconomicUserToken: React.FC<Partial<UsersIntergrationFormProps>> = ({ config }) => {
  const dataObject = {
    economicUserToken: `${config?.economic.users_integrations_api_data.economicUserToken}`,
    type: 'dayBookBooked',
    dayBookNumber: '[DAYBOOKNO]',
    fromSerialNumber: '[FROMSERIALNO]',
    toSerialNumber: '[TOSERIALNO]',
  };

  const jsonString = JSON.stringify(dataObject, null, 2);

  const highlightedEconomicData = syntaxHighlight(jsonString);

  return (
    <Card className="connection-card connection-card-economic">
      <Card.Title className="connection-card-header mt-5">E-conomic Webhook Data</Card.Title>
      <Card.Body className="p-0">
        <div className="connection-card-data">
          <pre className="p-0" dangerouslySetInnerHTML={{ __html: highlightedEconomicData }} />
          <CopyToClipboardButton textToCopy={jsonString} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default EconomicUserToken;
