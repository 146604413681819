import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import Select from 'react-select';
import { Currencies, SelectedCurrency } from '../../invoice/types';
import { allCurrencies } from '@src/Api';

export const EconomicInternationalCurrencies: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const [currencies, setCurrencies] = React.useState<Currencies[]>([]);

  React.useEffect(() => {
    const getCurrencies = async () => {
      const currenciesResponse = await allCurrencies();
      if (currenciesResponse) {
        setCurrencies(currenciesResponse.data);
      }
    };

    getCurrencies();
  }, []);

  const handleEuCurrencyChange = (selectedOption: SelectedCurrency | null) => {
    if (selectedOption && setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        currency_eu: selectedOption.value,
      });
    }
  };

  const handleOutsideEuCurrencyChange = (selectedOption: SelectedCurrency | null) => {
    if (selectedOption && setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        currency_outside_eu: selectedOption.value,
      });
    }
  };

  const currencyOptions = currencies.map((currency) => ({
    value: currency.currencies_code,
    label: currency.currencies_name,
  }));

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCurrency">
        <Form.Label column sm={6}>
          Specify currency for EU customers
        </Form.Label>
        <Col sm={6} className="custom-z-index">
          <Select
            classNamePrefix="react-select"
            aria-label="Floating label select"
            value={currencyOptions.find((option) => option.value === economicUserDefaultData.currency_eu)}
            onChange={handleEuCurrencyChange}
            options={currencyOptions}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCurrency">
        <Form.Label column sm={6}>
          Specify currency for customers outside the EU
        </Form.Label>
        <Col sm={6} className="custom-z-index">
          <Select
            classNamePrefix="react-select"
            aria-label="Floating label select"
            value={currencyOptions.find((option) => option.value === economicUserDefaultData.currency_outside_eu)}
            onChange={handleOutsideEuCurrencyChange}
            options={currencyOptions}
          />
        </Col>
      </Form.Group>
    </>
  );
};
