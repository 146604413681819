import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import companyLogo from '@public/img/itstack-logo-green-animated-1.svg';
import { Login } from './login';
import { ForgotPassword } from './forgotPassword';
import { motion } from 'framer-motion';

export const LoginPage: React.FC = () => {
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);

  const handleForgotPasswordClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsPasswordForgotten(!isPasswordForgotten);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm={10}>
            <Col sm={12} className="text-center">
              <motion.div initial={{ x: -200, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 1 }}>
                <img src={companyLogo} className="logo" alt="IT Stack logo with animation" loading="lazy" />
              </motion.div>
              {!isPasswordForgotten ? <Login /> : <ForgotPassword />}
              <Row className="align-items-center" style={{ height: '10vh' }}>
                <motion.div initial={{ x: 200, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 1 }}>
                  <Col className="text-center">
                    <a href="/#" onClick={handleForgotPasswordClick}>
                      {!isPasswordForgotten ? 'Forgot Password?' : 'Back to login.'}
                    </a>
                  </Col>
                </motion.div>
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};
