import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { employeeGroupsByUser } from '@src/Api';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import type { EmployeeGroups } from './types';

export const EmployeeForm: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const [employeeGroups, setEmployeeGroups] = React.useState<EmployeeGroups[]>([]);

  React.useEffect(() => {
    const getEmployeeGroups = async () => {
      const employeeGroupsResponse = await employeeGroupsByUser();
      if (employeeGroupsResponse) {
        setEmployeeGroups(employeeGroupsResponse.data);
      }
    };

    getEmployeeGroups();
  }, []);

  const handleEmployeeGroupChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        employee_group: parseInt(`${option.value}`),
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmployeeGroup">
        <Form.Label column sm={6}>
          Specify default employee group
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectEmployeeGroup" label="Select employee group">
            <Form.Select aria-label="Floating label select" value={economicUserDefaultData.employee_group} onChange={handleEmployeeGroupChange}>
              <option hidden value="">
                Open this select menu
              </option>
              {employeeGroups.length !== 0 &&
                employeeGroups.map((employeeGroup) => (
                  <option key={`employeeGroup-${employeeGroup.number}`} value={employeeGroup.number}>
                    {employeeGroup.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
