import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { ReferenceOptions } from '../types';

export const EconomicReferenceText2: React.FC<ReferenceOptions> = ({ economicUserDefaultData, setEconomicUserDefaultData, referenceOptions }) => {
  const handleReferenceText2Change = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        reference_text_2: option.value,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
      <Form.Label column sm={6}>
        Select value for text 2
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectReferenceText2Value" label="Select reference text 2">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.reference_text_2} onChange={handleReferenceText2Change}>
            {referenceOptions('text-2')}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
