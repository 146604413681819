import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { EconomicProductSettings } from './types';

export const AdvancedInvoiceLines: React.FC<EconomicProductSettings> = ({ economicUserDefaultData, handleFormCheckChange }) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalAdvancedInvoiceLines">
      <Form.Label column sm={6}>
        Enable advanced invoice product lines
      </Form.Label>
      <Col sm={6}>
        <Form.Check
          type="switch"
          id="adv-invoice-switch"
          label={economicUserDefaultData.advanced_invoice_lines ? 'Enabled' : 'Disabled'}
          checked={economicUserDefaultData.advanced_invoice_lines}
          onChange={handleFormCheckChange}
          data-key="advanced_invoice_lines"
        />
      </Col>
    </Form.Group>
  );
};
