import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { EconomicProductSettings } from './types';

export const CrewProductUnit: React.FC<EconomicProductSettings> = ({ economicUserDefaultData, units, handleSelectChange }) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalProductUnit">
      <Form.Label column sm={6}>
        Specify unit for Crew
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectCrewUnit" label="Select product unit">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.crew_unit?.unitNumber} onChange={handleSelectChange}>
            <option data-key={'crew'}>Open this select menu</option>
            {units &&
              units.length !== 0 &&
              units.map((unit) => (
                <option
                  key={`crewUnit-${unit.unitNumber}`}
                  value={unit.unitNumber}
                  data-self={unit.self}
                  data-key={'crew_unit'}
                  data-numberkey={'unitNumber'}
                  data-selfkey={'self'}
                >
                  {unit.name}
                </option>
              ))}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
