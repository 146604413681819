import React from 'react';
import { RentmanSettingModuleProps } from '../../types';
import { Row, Col, Form } from 'react-bootstrap';

export const ChooseRentmanStockLocation: React.FC<RentmanSettingModuleProps> = ({
  rentmanUserDefaultData,
  setRentmanUserDefaultData,
  chooseStockLocationSyncError,
}) => {
  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      choose_stock_location_active: event.target.checked,
    });
  };

  const handleCustomFieldNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      custom_stock_field_name: event.target.value,
    });
  };

  const handleChosenStockLocationChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      chosen_stock_location: event.target.value,
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={6}>
          Enable choosing which stock location you want to transfer invoices from
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="choose-stock-location-switch"
            label={rentmanUserDefaultData.choose_stock_location_active ? 'Enabled' : 'Disabled'}
            checked={rentmanUserDefaultData.choose_stock_location_active ? rentmanUserDefaultData.choose_stock_location_active : false}
            onChange={handleFormCheckChange}
          />
        </Col>
      </Form.Group>
      {rentmanUserDefaultData.choose_stock_location_active && rentmanUserDefaultData.choose_stock_location_active === true && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={6}>
              Specify the name of the Rentman custom field containing chosen stock location
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                type="text"
                aria-label="Floating Custom Field Control"
                className={chooseStockLocationSyncError && rentmanUserDefaultData.choose_stock_location_active === true ? 'error-field' : ''}
                value={rentmanUserDefaultData.custom_stock_field_name}
                onChange={handleCustomFieldNameChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={6}>
              Specify which stock location you would like to transfer invoices from
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                type="text"
                aria-label="Floating control"
                className={chooseStockLocationSyncError && rentmanUserDefaultData.choose_stock_location_active === true ? 'error-field' : ''}
                value={rentmanUserDefaultData.chosen_stock_location}
                onChange={handleChosenStockLocationChange}
              />
            </Col>
          </Form.Group>
        </>
      )}
    </>
  );
};
