import { ContentSection } from '@src/components/content-section';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const TermsAndConditionsPage: React.FC = () => {
  const contents = [
    {
      title: 'Selskabsoplysninger',
      anchor: 'selskabsoplysninger',
      content: [
        { list: ['IT Stack ApS', 'Østerbro 20 1. th', '5000 Odense C', 'Telefon: 78 70 31 43', 'Email: mail@itstack.dk', 'Selskabets CVR-nr.: 39898985'] },
      ],
    },
    {
      title: 'Generelt',
      anchor: 'generelt',
      content: [
        {
          sections: [
            'Nedenstående generelle vilkår og betingelser anvendes, og gælder ved levering af vores produkter og ydelser, medmindre andet er skriftligt aftalt. IT Stack ApS forbeholder sig retten til at ændre handelsbetingelser uden varsel.',
            ' Tilbud fra IT Stack ApS udarbejdes specifikt for den enkelte kunde, og gør sig kun gældende i 14 dage, såfremt andet ikke er angivet i tilbuddet. En aftale gør sig først gældende, når IT Stack ApS har modtaget en skriftlig accept fra kunden. De tilbudte priser gælder kun under forudsætning af at kunden ikke ændrer opgaven undervejs. I tilfælde af ændringer fra kunden, vil dette blive faktureret særskilt i henhold til aftale mellem parterne. ',
            'Betingelserne udgør sammen med tilbud, databehandleraftale, ordrebekræftelse og andre skriftlige aftaler afgivet af IT Stack, det samlede aftalegrundlag om IT Stacks salg og levering af serviceydelser og produkter til Kunden.',
          ],
        },
      ],
    },
    {
      title: 'Ydelser',
      anchor: 'ydelser',
      content: [
        'IT Stacks ydelse består i levering af udvikling / optimering af Kundens website og/eller materiale til online markedsføring samt præsentation af materialet på udvalgte markedsføringskanaler. Ydelsen leveres i henhold til beskrivelsen i fremsendte tilbud. Ydelsen leveres i tæt samarbejde med Kunden, således at alle dele af Ydelsen godkendes af Kunden forud for eksponering til offentligheden.',
      ],
    },
    {
      title: 'Kundens medvirken',
      anchor: 'kundens-medvirken',
      content: [
        'Kunden er forpligtet til aktivt at medvirke til IT Stacks opfyldelse af aftalen, herunder udstede eventuelle nødvendige fuldmagter, stille oplysninger til rådighed, og give IT Stack adgang til Kundens markedsføringskanaler, således at IT Stack uden ulempe har mulighed for at levere sine ydelser.',
      ],
    },
    {
      title: 'Betaling og fakturering',
      anchor: 'betaling-og-fakturering',
      content: [
        {
          sections: [
            'Alle priser angives i danske kroner eksklusiv moms.',
            'Udgifter til køb af softwarelicenser eller andet som er nødvendigt i direkte forbindelse med udførelse af serviceydelsen, refunderes af kunden til kostpris plus et håndteringstillæg på 10%, medmindre andet er aftalt skriftligt.',
            'Betalingsfrist er 8 dage netto.',
            'Såfremt Kunden undlader at betale fakturaen rettidigt, har IT Stack ret til at stoppe arbejdet, indtil betaling er modtaget, og uden varsel at fratage Kunden brugsretten til det leverede produkt.',
            'Ved for sen betaling pålægges i øvrigt 1,5 % i rente pr. påbegyndt måned.',
            'Efter 12 måneder kan IT Stack årligt hæve enhedsprisen svarende til stigningen i det danske forbrugerprisindeks, dog mindst med 3 %. Ved beregningen af forbrugerprisindekset tages udgangspunkt i det indeks, der er gældende på ikrafttrædelsesdatoen.',
          ],
        },
      ],
    },
    {
      title: 'Betingelser ved abonnementsydelser',
      anchor: 'betingelser-ved-abonnementsydelser',
      content: [
        'Et abonnement er bindende fra den datoen, som er udspecificeret i den indgåede aftale, eller i fakturaen. Efter bindingsperioden kan aftalen opsiges med en måneds varsel forud for skæringsdatoen for aftalen, der allerede er defineret i det oprindelige aftalegrundlag. Kunden kan til hver en tid opsige abonnementsaftalen indgået med IT Stack ApS, men skal dog fortsætte betalingen til bindingsperiodens udløb. IT Stack ApS forbeholder sig retten til at annullere en aftale til enhver tid og med øjeblikkelig virkning, og dermed lukke for en kundes aktiviteter, såfremt der foreligger beviser for at kunden har forbrudt sig mod aftalen. I sådan et tilfælde skal kunden fortsat betale for hele bindingsperioden. Abonnementsaftalen kan ikke overdrages eller sælges til tredjepart af kunden uden skriftlig accept fra IT Stack ApS.',
      ],
    },
    {
      title: 'Opsigelse af aftale og/eller samarbejde',
      anchor: 'opsigelse-af-aftale',
      content: [
        'IT Stack ApS forbeholder sig retten til at opsige et samarbejde og dermed fratræde en opgave med øjeblikkelig virkning, i fald kunden ikke overholder aftaler, deadlines, møder, materialelevering og manglende tilbagemelding.',
      ],
    },
  ];

  return (
    <Row className="account-content">
      <Col lg={12}>
        {contents.map((content) => (
          <div key={content.anchor} id={content.anchor}>
            <ContentSection {...content} />
          </div>
        ))}
      </Col>
    </Row>
  );
};
