import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import { paymentTermsByUser } from '@src/Api';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';
import type { PaymentTerms } from './types';

export const EconomicPaymentTerms: React.FC<EconomicSettingModuleProps> = ({
  economicUserDefaultData,
  setEconomicUserDefaultData,
  handleSelectChange,
  currentMonthPaymentTermsError,
  setCurrentMonthPaymentTermsError,
}) => {
  const [paymentTerms, setPaymentTerms] = React.useState<PaymentTerms[]>([]);

  React.useEffect(() => {
    const getPaymentTerms = async () => {
      const paymentTermsResponse = await paymentTermsByUser();
      if (paymentTermsResponse) {
        setPaymentTerms(paymentTermsResponse.data);
      }
    };

    getPaymentTerms();
  }, []);

  const handleAutoPaymentTermsFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAutoPaymentTermsEnabled = event.target.checked;
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        autoPaymentTerms: event.target.checked,
      });

      if (isAutoPaymentTermsEnabled === false) {
        setEconomicUserDefaultData({
          ...economicUserDefaultData,
          autoPaymentTerms: event.target.checked,
          current_month_payment_terms: false,
        });
      }
    }
  };

  const handleCurrentMonthPaymentTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        current_month_payment_terms: event.target.checked,
      });
    }
  };

  const handleEconomicPaymentTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        economic_payment_terms: event.target.checked,
      });
    }
  };

  const handlePaymentTermCustomFieldName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setCurrentMonthPaymentTermsError) {
      setCurrentMonthPaymentTermsError(null);
    }

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        current_month_payment_terms_custom_field_name: event.target.value,
      });
    }
  };

  const showPaymentTermsSelectbox = (): boolean => {
    if (!economicUserDefaultData.autoPaymentTerms) {
      return true;
    } else if (economicUserDefaultData.autoPaymentTerms && economicUserDefaultData.autoPaymentTerms !== true) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEconomicPaymentTerms">
        <Form.Label column sm={6}>
          Enable using payment terms from e-conomic customer
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="auto-calc-switch"
            label={economicUserDefaultData.economic_payment_terms ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.economic_payment_terms ? economicUserDefaultData.economic_payment_terms : false}
            onChange={handleEconomicPaymentTerms}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalAutoPaymentTerms">
        <Form.Label column sm={6}>
          Enable automatic calculate payment terms
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="auto-calc-switch"
            label={economicUserDefaultData.autoPaymentTerms ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.autoPaymentTerms ? economicUserDefaultData.autoPaymentTerms : false}
            onChange={handleAutoPaymentTermsFormChange}
          />
        </Col>
      </Form.Group>

      {economicUserDefaultData.autoPaymentTerms === true && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={6}>
            Enable using payment terms for current month
          </Form.Label>
          <Col sm={6}>
            <Form.Check
              type="switch"
              id="payment-terms-switch"
              label={economicUserDefaultData.current_month_payment_terms ? 'Enabled' : 'Disabled'}
              checked={economicUserDefaultData.current_month_payment_terms ? economicUserDefaultData.current_month_payment_terms : false}
              onChange={handleCurrentMonthPaymentTerms}
            />
          </Col>
        </Form.Group>
      )}

      {economicUserDefaultData.current_month_payment_terms === true && economicUserDefaultData.autoPaymentTerms === true && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={6}>
            Specify name of payment term custom field
          </Form.Label>
          <Col sm={6}>
            <Form.Control
              className={currentMonthPaymentTermsError ? 'error-field' : ''}
              type="text"
              aria-label="Floating Custom Field Control"
              value={
                economicUserDefaultData.current_month_payment_terms_custom_field_name
                  ? economicUserDefaultData.current_month_payment_terms_custom_field_name
                  : ''
              }
              onChange={handlePaymentTermCustomFieldName}
            />
            <div className="error-container-settings">
              <p className={currentMonthPaymentTermsError ? 'error-text' : ''}>{currentMonthPaymentTermsError}</p>
            </div>
          </Col>
        </Form.Group>
      )}

      {showPaymentTermsSelectbox() === true && economicUserDefaultData.current_month_payment_terms === false && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPaymentTerms">
          <Form.Label column sm={6}>
            Specify default payment terms
          </Form.Label>
          <Col sm={6}>
            <FloatingLabel controlId="floatingSelectPaymentTerms" label="Select payment terms">
              <Form.Select aria-label="Floating label select" value={economicUserDefaultData.payment_terms?.paymentTermsNumber} onChange={handleSelectChange}>
                <option hidden value="" data-key={'payment_terms'}>
                  Open this select menu
                </option>
                {paymentTerms.length !== 0 &&
                  paymentTerms.map((paymentTerm) => (
                    <option
                      key={`paymentTerm-${paymentTerm.paymentTermsNumber}`}
                      value={paymentTerm.paymentTermsNumber}
                      data-self={paymentTerm.self}
                      data-key={'payment_terms'}
                      data-numberkey={'paymentTermsNumber'}
                      data-selfkey={'self'}
                    >
                      {paymentTerm.name}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
